import styled from "styled-components";
import css from "@styled-system/css";
import theme from "~tokens";

export const BannerFooter = styled("div")(
  css({
    display: "flex",
    alignItems: "stretch",
    marginTop: "10px",
  }),
);

export const FooterDescription = styled("span")(
  css({
    font: theme.fonts.textBoldSmall,
    color: theme.colors.gray400,
    position: "relative",
    top: "10px",
    flex: 1,
    maxWidth: "159px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }),
);
