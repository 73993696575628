import React from "react";
import PropTypes from "prop-types";
import { PanelStyle } from "./PanelStyle";

const Panel = ({ children, ...attrs }) => <PanelStyle {...attrs}>{children}</PanelStyle>;

Panel.propTypes = {
  children: PropTypes.node,
};

Panel.defaultProps = {
  children: "",
};

export default Panel;
