/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback, Fragment } from "react";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import { ChevronDownIcon, ClearIcon } from "~svgs";
import Icon from "~core/icon";
import Checkbox from "~form/checkbox";
import Label from "~form/label";
import {
  Container,
  Tags,
  Tag,
  SearchInput,
  OptionsList,
  Group,
  GroupItem,
} from "./MultiselectStyle";
import theme from "~tokens";

const Multiselect = ({
  listItems,
  label: labelValue,
  required,
  keyValue,
  onChange,
  name,
  ...attrs
}) => {
  const tagsRef = useRef(null);
  const selectRef = useRef(null);
  const searchRef = useRef(null);
  const optionsRef = useRef(null);
  const [search, setSearch] = useState("");
  const [tags, setTags] = useState({
    freeSpace: null,
    itemsToFit: null,
    clippedItems: 0,
  });
  const [openOptions, setOpenOptions] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [allValues, setAllValues] = useState([]);

  useEffect(() => {
    if (listItems && keyValue) {
      const values = [];

      listItems.forEach((listItem) => {
        if (listItem.data?.length) {
          listItem.data.forEach((item) => {
            values.push(item.value);
          });
        } else {
          values.push(listItem.value);
        }
      });

      setAllValues(values);
    }
  }, [listItems, keyValue]);

  useEffect(() => {
    if (listItems) {
      const selected = listItems
        .reduce((accum, curr) => (curr?.data ? [...accum, ...curr.data] : [...accum, curr]), [])
        .filter((item) => item.selected);

      setSelectedItems(selected);
    }
  }, [listItems, setSelectedItems]);

  const getFreeSpace = () => {
    const searchWidth = searchRef.current.getBoundingClientRect().width - 32;
    const tagsWidth = tagsRef.current.getBoundingClientRect().width;
    return searchWidth - tagsWidth;
  };

  const calcFreeSpace = () => {
    const freeSpace = getFreeSpace();
    return setTags({ ...tags, freeSpace });
  };

  const addItem = (value) => {
    const { freeSpace, itemsToFit, clippedItems } = tags;

    setSearch("");
    searchRef.current.focus();

    return setSelectedItems((olders) => {
      if (itemsToFit === null && freeSpace < 200) {
        setTags({
          ...tags,
          itemsToFit: olders.length,
          clippedItems: clippedItems + 1,
        });
      } else if (itemsToFit) {
        setTags({ ...tags, clippedItems: olders.length });
      }
      const added = [...olders, value];
      onChange(added);
      return added;
    });
  };

  const removeItem = (item) => {
    const filterItems = ({ value }) => value[keyValue] !== item.value[keyValue];
    return setSelectedItems((olders) => {
      const { itemsToFit, clippedItems } = tags;
      if (itemsToFit !== null && olders.length - 1 >= itemsToFit) {
        setTags({ ...tags, clippedItems: clippedItems - 1 });
      }
      if (olders.length - 1 === itemsToFit) {
        setTags({ ...tags, itemsToFit: null, clippedItems: clippedItems - 1 });
      }

      const filtred = olders.filter(filterItems);
      onChange(filtred);
      return filtred;
    });
  };

  const getItemLabel = useCallback(
    (label) => {
      if (search && label.toLowerCase().includes(search)) {
        const parsedLabel = label.toLowerCase().replace(search, `<strong>${search}</strong>`);
        parsedLabel.charAt(0).toUpperCase();
        return parse(parsedLabel);
      }
      return label;
    },
    [search],
  );

  const renderOption = (item, label, values) => {
    const itemSelected = selectedItems.some(
      ({ value: selectedItem }) => selectedItem[keyValue] === item.value[keyValue],
    );
    const indexValue = values.findIndex((value) => value[keyValue] === item.value[keyValue]);

    return (
      <GroupItem
        option
        aria-selected={itemSelected}
        tabIndex={indexValue}
        key={`vli-group-item-${indexValue}`}
        id={`vli-group-item-${indexValue}`}
        onClick={() => {
          if (!itemSelected) {
            addItem(item);
          } else {
            removeItem(item);
          }
        }}
      >
        <Checkbox
          label={getItemLabel(label)}
          key={`${label}-${item.value[keyValue]}`}
          id={`${label}-${item.value[keyValue]}`}
          checked={itemSelected}
          group={label}
          onChange={({ target: { checked } }) => {
            if (checked) addItem(item);
            else removeItem(item);
          }}
        />
      </GroupItem>
    );
  };

  const renderOptions = ({ label, data, value }) => {
    if (data?.length) {
      return (
        <Group title={label} key={label}>
          {data.map((item) => renderOption(item, item.label, allValues))}
        </Group>
      );
    }
    if (value) {
      return renderOption({ label, value }, label, allValues);
    }
    return null;
  };

  const renderTags = (item, i) => {
    if (i < tags.itemsToFit || tags.itemsToFit === null) {
      return (
        <Tag key={i}>
          {item.label}
          <Icon
            className="clear-button"
            src={ClearIcon}
            size={16}
            onClick={(e) => {
              e.stopPropagation();
              removeItem(item);
            }}
          />
        </Tag>
      );
    }
    return null;
  };

  const filtering = useCallback(
    ({ label, data, value }) => {
      if (data) {
        return { label, data: data.filter((item) => item.label.toLowerCase().includes(search)) };
      }
      if (label.toLowerCase().includes(search)) {
        return { label, value };
      }
      return null;
    },
    [search],
  );

  useEffect(() => {
    calcFreeSpace();
  }, [selectedItems]);

  useEffect(() => {
    if (search.length > 0) {
      const filteredOptions = listItems.map(filtering).filter((item) => item !== null);
      setFilteredItems(filteredOptions);
    }
  }, [search, listItems, filtering]);

  useEffect(() => {
    const selectNode = selectRef.current;
    if (selectNode) {
      document.addEventListener("click", (e) => {
        if (e.target !== selectNode && !selectNode.contains(e.target)) {
          setOpenOptions(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    const freeSpace = getFreeSpace();

    if (freeSpace < 200 && tags.itemsToFit === null) {
      setTags({
        freeSpace,
        itemsToFit: tags.itemsToFit + 1,
        clippedItems: selectedItems.length - 1,
      });
    }
  }, [selectedItems]);

  return (
    <Fragment>
      <div>
        {labelValue && (
          <Label
            informative={!required && "(Opcional)"}
            style={{ marginBottom: theme.spacing.xss }}
          >
            {labelValue}
          </Label>
        )}
      </div>
      <Container
        role="combobox"
        aria-haspopup="listbox"
        aria-owns="vli-optional-list"
        aria-expanded={search.length > 0}
        ref={selectRef}
        {...attrs}
      >
        <Tags
          ref={tagsRef}
          spaceChildren={2}
          alignRight={search.length > 0}
          onClick={() => searchRef.current.focus()}
        >
          {search.length === 0 && selectedItems.map(renderTags)}
          {search.length > 0 && selectedItems.length > 0 && <Tag>+{selectedItems.length}</Tag>}
          {tags.clippedItems > 0 && search.length === 0 && <Tag>+{tags.clippedItems}</Tag>}
        </Tags>
        <SearchInput
          name={name}
          role="searchbox"
          aria-controls="vli-optional-list"
          aria-autocomplete="both"
          ref={searchRef}
          type="text"
          value={search}
          onFocus={() => {
            setOpenOptions(true);
          }}
          openOptions={openOptions}
          onChange={({ target: { value } }) => setSearch(value.toLowerCase())}
        />
        <Icon
          className="drop-options--trigger"
          onClick={() => {
            if (openOptions) setOpenOptions(false);
            else {
              searchRef.current.focus();
              setOpenOptions(true);
            }
          }}
          src={ChevronDownIcon}
          size={16}
        />
        {openOptions && (
          <OptionsList ref={optionsRef} id="vli-optional-list" role="listbox">
            {search.length === 0 ? listItems.map(renderOptions) : filteredItems.map(renderOptions)}
          </OptionsList>
        )}
      </Container>
    </Fragment>
  );
};

Multiselect.propTypes = {
  listItems: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        data: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.object,
          }),
        ),
      }),
    ),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.object,
      }),
    ),
  ]).isRequired,
  label: PropTypes.string,
  keyValue: PropTypes.string,
  required: PropTypes.bool,
  sections: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
};

Multiselect.defaultProps = {
  label: "",
  required: false,
  sections: true,
  keyValue: "id",
  onChange: () => {},
  name: "",
};

export default Multiselect;
