import React from "react";
import PropTypes from "prop-types";
import { RowStyle } from "./RowStyle";

const Row = ({ children, ...attrs }) => (
  <RowStyle {...attrs} data-testid="row">
    {children}
  </RowStyle>
);

Row.propTypes = {
  children: PropTypes.node,
};

Row.defaultProps = {
  children: "",
};

export default Row;
