import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Icon from "~core/icon";
import Flex from "~core/flex";
import Text from "~typography/text";
import { ChevronUpIcon } from "~svgs";

import { ContainerProfile, BodyNav } from "./profileStyle";

const Profile = ({ userName, avatar, children, open, ...attrs }) => {
  const [displayClass, setDisplayClass] = useState("hide");
  const dropdownRef = useRef();

  useEffect(() => {
    const selectNode = dropdownRef.current;
    if (selectNode) {
      document.addEventListener("click", (e) => {
        if (e.target !== selectNode && !selectNode.contains(e.target)) {
          setDisplayClass("hide");
        }
      });
    }
  }, []);

  return (
    <ContainerProfile {...attrs} ref={dropdownRef} id="profile" data-testid="profile">
      <nav>
        <BodyNav onClick={() => setDisplayClass(displayClass === "hide" ? "" : "hide")}>
          <Flex alignItems="center" justifyContent="flex-start" width="100%">
            {avatar && avatar}
            <Text title={userName} ml={avatar && 2} className="username">
              {userName}
            </Text>
            <Icon src={ChevronUpIcon} className={`toggle ${displayClass}`} viewBox="0 0 16 16" />
          </Flex>
        </BodyNav>
        <ul className={displayClass}>{children}</ul>
      </nav>
    </ContainerProfile>
  );
};

Profile.propTypes = {
  avatar: PropTypes.node,
  userName: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool,
};

Profile.defaultProps = {
  avatar: "",
  userName: "User Name",
  children: "",
  open: false,
};

export default Profile;
