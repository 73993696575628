import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, Toast } from "~core/feedback";
import { BannerFooter, FooterDescription } from "./BannerStyle";
import Button from "~core/button/Button";

const Banner = ({
  children,
  title,
  onClose,
  isOpen,
  buttonName,
  onSubmit,
  footerDescription,
  autoClose,
  delay,
  error,
  warning,
  success,
  ...attrs
}) => (
  <ToastContainer data-testid="banner">
    <Toast
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      autoClose={autoClose}
      error={error}
      warning={warning}
      success={success}
      delay={delay}
      {...attrs}
    >
      {children}
      <BannerFooter>
        <FooterDescription>{footerDescription}</FooterDescription>
        <Button variant="tertiary" name={buttonName} action={onSubmit} />
      </BannerFooter>
    </Toast>
  </ToastContainer>
);

Banner.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  buttonName: PropTypes.string,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  footerDescription: PropTypes.string,
  autoClose: PropTypes.bool,
  delay: PropTypes.number,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  success: PropTypes.bool,
};

Banner.defaultProps = {
  children: "",
  title: "",
  buttonName: "Ver mais",
  onClose: () => {},
  isOpen: false,
  onSubmit: () => {},
  footerDescription: "",
  autoClose: false,
  delay: 3000,
  error: false,
  warning: false,
  success: false,
};

export default Banner;
