import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Link from "~core/link";
import {
  CardContainer,
  Image,
  BodyContainer,
  Title,
  Text,
  IconsContainer,
  HeartContainer,
  TextHeart,
  TextContainer,
  LinkContainer,
} from "./FeedItemStyle";

import { HeartEmptyIcon, HeartFilledIcon, ShareIcon } from "~svgs";

const FeedItem = ({
  title,
  text,
  href,
  imageSrc,
  altImg,
  liked,
  onLike,
  onDislike,
  onShare,
  ...attrs
}) => {
  const [isLiked, setIsLiked] = useState(liked);
  const [likeText, setLikeText] = useState(liked ? "curtido" : "curtir");

  const handleLike = useCallback(
    (newState) => {
      if (newState) {
        setLikeText("curtido");
        onLike(newState);
      } else {
        setLikeText("curtir");
        onDislike(newState);
      }
    },
    [onDislike, onLike],
  );

  const handleClick = () => {
    const newState = !isLiked;
    handleLike(newState);
    setIsLiked(newState);
  };

  useEffect(() => {
    setIsLiked(liked);
    handleLike(liked);
  }, [liked, handleLike]);

  return (
    <CardContainer id="feed-item" data-testid="feed-item" {...attrs}>
      {imageSrc && <Image src={imageSrc} alt={altImg} />}
      <BodyContainer>
        <Title>{title}</Title>
        <TextContainer>
          <Text>{text}</Text>
          <LinkContainer>
            <Link tag="a" href={href} nameLink="Ver Mais" textDecoration="none" />
          </LinkContainer>
        </TextContainer>
      </BodyContainer>
      <IconsContainer>
        <HeartContainer onClick={handleClick} liked={isLiked}>
          {isLiked ? <HeartFilledIcon className="liked" /> : <HeartEmptyIcon />}
          <TextHeart>{likeText}</TextHeart>
        </HeartContainer>
        <ShareIcon className="share" onClick={onShare} />
      </IconsContainer>
    </CardContainer>
  );
};

FeedItem.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  href: PropTypes.string,
  imageSrc: PropTypes.string,
  altImg: PropTypes.string,
  liked: PropTypes.bool,
  onLike: PropTypes.func,
  onDislike: PropTypes.func,
  onShare: PropTypes.func,
};

FeedItem.defaultProps = {
  title: "",
  text: "",
  href: "",
  imageSrc: "",
  altImg: "",
  liked: true,
  onLike: () => {},
  onDislike: () => {},
  onShare: () => {},
};

export default FeedItem;
