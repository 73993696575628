import React from "react";
import PropTypes from "prop-types";
import { EmptyStateIcon, NoResultsIcon } from "~svgs";
import { EmptyStateContainer, EmptyStateBox, IconStyle, ContentStyle } from "./EmptyStateStyle";
import Heading from "~typography/heading";
import Text from "~typography/text";

const EmptyState = ({ condensed, title, description, ...attrs }) => (
  <EmptyStateContainer id="empty-state" data-testid="empty-state" {...attrs}>
    <EmptyStateBox condensed={condensed}>
      <IconStyle condensed={condensed}>
        {condensed ? <NoResultsIcon /> : <EmptyStateIcon />}
      </IconStyle>
      <ContentStyle condensed={condensed}>
        <Heading tag="h2">{title}</Heading>
        {!condensed && <Text>{description}</Text>}
      </ContentStyle>
    </EmptyStateBox>
  </EmptyStateContainer>
);

EmptyState.propTypes = {
  condensed: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
};

EmptyState.defaultProps = {
  condensed: false,
  title: "Nenhum resultado encontrado.",
  description: "",
};

export default EmptyState;
