import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import generateUUID from "~util/generateUUID";
import { ImagePlaceholder } from "~svgs";
import { ImageTag } from "./MediaCardStyle";

const Image = ({
  src, title, alt, ...attrs
}) => {
  const [mediaSrc, setMedia] = useState(true);
  const uuid = generateUUID();

  useEffect(() => {
    if (
      document.getElementById(uuid).complete
      && document.getElementById(uuid).src !== window.location.href
    ) {
      setMedia(false);
    }
  }, [uuid]);

  return (
    <Fragment>
      <ImageTag
        src={src}
        id={uuid}
        title={title}
        alt={alt}
        style={{
          borderRadius: !title ? "4px" : "4px 4px 0px 0px",
          visibility: mediaSrc ? "hidden" : "visible",
        }}
        {...attrs}
      />
      {mediaSrc && <ImagePlaceholder />}
    </Fragment>
  );
};

Image.propTypes = {
  title: PropTypes.string,
  alt: PropTypes.string,
  src: PropTypes.string,
};

Image.defaultProps = {
  title: "",
  alt: "",
  src: "",
};

export default Image;
