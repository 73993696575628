import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { DownloadIcon } from "~svgs";
import { IconSource } from "./IconStyle";

const Icon = ({ className, src, size, "aria-label": label, ...attrs }) => {
  const classes = classNames(className);

  return typeof src === "function" ? (
    <IconSource
      id="svg"
      as={src}
      width={`${size}px`}
      height={`${size}px`}
      className={classes}
      aria-label={label}
      viewBox={`0 0 ${src().props.width} ${src().props.height}`}
      {...attrs}
    />
  ) : (
    <IconSource
      id="svg"
      as={src}
      width={`${size}px`}
      height={`${size}px`}
      className={classes}
      aria-label={label}
      {...attrs}
    />
  );
};

Icon.propTypes = {
  src: PropTypes.any,
  className: PropTypes.string,
  size: PropTypes.number,
  "aria-label": PropTypes.string,
};

Icon.defaultProps = {
  "aria-label": "icon",
  src: DownloadIcon,
  size: 24,
  className: "",
};

export default Icon;
