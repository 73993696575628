import styled from "styled-components";
import css from "@styled-system/css";
import theme from "~tokens";

export const MediaWrapper = styled("section")(
  css({
    width: "100%",
    display: "inline-block",
    boxSizing: "border-box",
    background: theme.colors.grayWhite,
    margin: "8px",
    borderRadius: "4px",
  }),
);

export const MediaSource = styled("div")(
  css({
    width: "100%",
    background: theme.colors.accentPrimaryLight,
    backgroundSize: "100%",
    display: "flex",
    alignItems: "center",
    minHeight: "125px",
    position: "relative",
    "& > iframe": {
      transition: "0.5s",
      display: "block",
      width: "100%",
      minHeight: "125px",
      background: "#000000",
    },
    "& > svg": {
      margin: "0 auto",
      display: "block",
      position: "relative",
      top: "40%",
      width: "72px",
    },
  }),
);

export const ImageTag = styled("img")(
  css({
    transition: "0.5s",
    display: "block",
    width: "100%",
    backgroundSize: "100%",
    objectFit: "cover",
  }),
);

export const IframeVideoContent = styled("div")(
  css({
    position: "relative",
    width: "100%",
    paddingBottom: "56.25%",
    "& > iframe": {
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      border: "0",
    },
  }),
);

export const PlayButtonContainer = styled("div")(
  css({
    width: "100%",
  }),
  ({ open }) => !open
    && css({
      position: "relative",
      paddingBottom: "56.25%",
    }),
  ({ open, thumbnail }) => !open
    && thumbnail
    && css({
      background: `url(${thumbnail}) no-repeat center center`,
      backgroundSize: "100%",
    }),
);

export const PlayButtonContent = styled("div")(
  css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    border: "0",
    "& > svg": {
      cursor: "pointer",
    },
  }),
);

export const VideoTag = styled("video")(
  css({
    transition: "0.5s",
    display: "block",
    width: "100%",
    minHeight: "125px",
    background: "#000000",
  }),
);

export const Article = styled("article")(
  css({
    width: "100%",
    background: theme.colors.grayWhite,
    padding: "0px 16px 12px",
    paddingTop: "0",
    borderRadius: "0px 0px 4px 4px",
    "& > header": {
      display: "block",
      width: "100%",
      "& > h1": {
        font: theme.fonts.textRegularMedium,
        fontWeight: 600,
        marginBottom: "6px",
        marginTop: "14px",
        color: theme.colors.gray500,
      },
    },
    "& > p": {
      font: theme.fonts.textRegularSmall,
      color: theme.colors.gray500,
    },
  }),
);
