import React from "react";
import PropTypes from "prop-types";
import { Container, ContainerInput } from "./SwitchStyle";
import Label from "~form/label";

const Switch = ({ label, checked, disabled, labelAlign, onChange, ...attrs }) => (
  <Container id="switch" data-testid="switch">
    {labelAlign === "left" && <Label>{label}</Label>}
    <ContainerInput>
      <input
        checked={checked}
        disabled={disabled}
        onChange={(e) => {
          onChange(e);
        }}
        {...attrs}
        type="checkbox"
      />
      <span className="slider round" />
    </ContainerInput>
    {labelAlign === "right" && label && <label>{label}</label>}
  </Container>
);

Switch.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  labelAlign: PropTypes.string,
  onChange: PropTypes.func,
};

Switch.defaultProps = {
  label: "",
  checked: false,
  disabled: false,
  labelAlign: "right",
  onChange: () => {},
};

export default Switch;
