import styled from "styled-components";
import theme from "~tokens";

const Container = styled.div`
  width: 100%;
  ${({ height }) => height && `height: ${height}px; overflow: auto;`}
  box-sizing: border-box;

  margin-left: auto;
  margin-right: auto;
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
  ${theme.media.mobile} {
    padding-top: 55px;
  }
  ${theme.media.xxl} {
    max-width: ${theme.breakpoints[4]};
  }
  ${theme.media.xl} {
    max-width: ${theme.breakpoints[3]};
  }
  ${theme.media.lg} {
    max-width: ${theme.breakpoints[2]};
  }
  ${theme.media.md} {
    max-width: ${theme.breakpoints[1]};
  }
  ${theme.media.xs} {
    max-width: ${theme.breakpoints[0]};
  }
`;
export default Container;
