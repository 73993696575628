import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { TextTag } from "./TextStyle";

const Text = ({
  tag, size, variant, ...attrs
}) => {
  const manageVariant = classNames(variant).split(" ");

  return <TextTag as={tag} size={size} variant={manageVariant} {...attrs} />;
};

Text.propTypes = {
  variant: PropTypes.array,
  size: PropTypes.string,
  className: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

Text.defaultProps = {
  variant: [],
  size: "",
  className: "",
  tag: "p",
};

export default Text;
