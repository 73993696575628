import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { LoadingPrimary, LoadingSecondary, Logo } from "~svgs";
import Heading from "~typography/heading";
import { LoadingStyle, Container, RotateContainerLoading } from "./loadingStyle";

const Loading = ({ title, message, type, fitParent, size }) => {
  const renderType = () => {
    switch (type) {
      case "primary":
        return (
          <Fragment>
            <RotateContainerLoading size={size || "80px"} data-testid="loading-primary">
              <LoadingPrimary />
            </RotateContainerLoading>
            <Heading tag="h4" color="#336EAE" fontWeight="600">
              {title}
            </Heading>
            <Heading tag="span" size={14} color="#949494">
              {message}
            </Heading>
          </Fragment>
        );
      case "secondary":
        return (
          <Fragment>
            <RotateContainerLoading size={size || "40px"}>
              <LoadingSecondary />
            </RotateContainerLoading>
          </Fragment>
        );
      default:
        return <Logo />;
    }
  };

  return (
    <LoadingStyle fitParent={fitParent}>
      <Container>{renderType()}</Container>
    </LoadingStyle>
  );
};

Loading.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.string,
  fitParent: PropTypes.bool,
  size: PropTypes.string,
};

Loading.defaultProps = {
  title: "",
  message: "",
  type: "primary",
  fitParent: false,
  size: "",
};

export default Loading;
