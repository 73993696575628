import styled from "styled-components";
import css from "@styled-system/css";
import theme from "~tokens";

export const DotCicle = styled("div")(
  css({
    display: "inline-block",
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    opacity: 1,
    boxShadow: "none",
    transition: "background .5s",
    border: "none",
    background: theme.colors.gray400,
    padding: 0,
    margin: 0,
    marginRight: "6px",
    outline: 0,
    cursor: "pointer",
  }),
  ({ active }) => (active
    ? `background: ${theme.colors.gray400}`
    : `
        background: ${theme.colors.gray400};
        opacity: 0.2;
  `),
);
