/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Tokens from "~tokens";
import Input from "~form/input";
import { TextSpan, Prefix, Suffix } from "./CellStyle";

const Cell = ({
  value: initialValue,
  row: { index },
  column: { id, editable, prefix, suffix, align, placeholder, error = () => false },
  updateData,
}) => {
  const [value, setValue] = useState(initialValue ? initialValue.toString() : "");

  const backgroundColor = error(value) && Tokens.colors.red200;

  const [style, setStyle] = useState({
    position: "unset",
    padding: `0 ${(prefix || suffix) && "10px"}`,
    textAlign: align,
    maxWidth: `${value.length}ch`,
    boxSizing: "content-box",
    minWidth: "3ch",
    backgroundColor,
  });
  const [groupStyle, setGroupStyle] = useState({
    position: "unset",
    border: "1px solid transparent",
    justifyContent: align,
    backgroundColor,
  });

  const updateStyle = () => {
    setStyle({
      textAlign: align,
      padding: `0 ${(prefix || suffix) && "10px"}`,
      maxWidth: `${value.length}ch`,
      boxSizing: "content-box",
      minWidth: "3ch",
      backgroundColor,
    });
    setGroupStyle({
      position: "unset",
      justifyContent: align,
      backgroundColor,
    });
  };

  const onChange = (e) => {
    updateStyle();
    setValue(e);
  };

  const onBlur = () => {
    setStyle({
      textAlign: align,
      padding: `0 ${(prefix || suffix) && "10px"}`,
      maxWidth: `${value.length}ch`,
      boxSizing: "content-box",
      minWidth: "3ch",
      backgroundColor,
    });
    setGroupStyle({
      position: "unset",
      border: "1px solid transparent",
      justifyContent: align,
      backgroundColor,
    });
    updateData(index, id, value);
  };

  if (!editable) {
    return (
      <TextSpan align={align} prefix={prefix} suffix={suffix}>
        {prefix && <Prefix>{prefix}</Prefix>}
        {value}
        {suffix && <Suffix>{suffix}</Suffix>}
      </TextSpan>
    );
  }

  return (
    <Input
      prefix={prefix}
      suffix={suffix}
      placeholder={placeholder}
      type="text"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      status={null}
      autocomplete={false}
      onClick={updateStyle}
      name={`${index}-${id}`}
      style={style}
      inputGroupStyle={groupStyle}
      tabIndex={index}
    />
  );
};

export default Cell;
