import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { LabelStyle } from "./TagStyle";

const Tag = ({ children, className, color, ...attrs }) => (
  <LabelStyle className={clsx(color, className)} {...attrs} data-testid="tag">
    {children}
  </LabelStyle>
);

Tag.propTypes = {
  children: PropTypes.string,
  className: PropTypes.any,
  color: PropTypes.string,
};

Tag.defaultProps = {
  children: "",
  className: "",
  color: "",
};

export default Tag;
