import styled from "styled-components";
import css from "@styled-system/css";
import { compose, color, space } from "styled-system";

export const IconSource = styled("")(
  ({ width }) => css({ width, path: { width } }),
  ({ height }) => css({ height, path: { height } }),
  ({ stroke }) => css({ path: { stroke } }),
  ({ fill }) => css({ path: { fill } }),
  compose(color, space)
);
