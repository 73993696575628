import React, { useState } from "react";
import PropTypes from "prop-types";
import { Input2faStyle } from "./Input2faStyle";

const Input2fa = ({ type, name, value, size, onChange, maxLength, width, ...attrs }) => {
  const setValue = (inputValue) => {
    let initialData = {};
    if (inputValue) {
      Array.from(inputValue).forEach((indexValue, key) => {
        initialData = { ...initialData, [`${name}-${key}`]: indexValue };
      });
    }
    return initialData;
  };

  const centerValue = size / 2 - 1;
  const inputs = Array.from({ length: size });
  const [token, setToken] = useState(setValue(value));

  const cleanField = (event, key) => {
    if (!event.target.value && key > 0 && key < size) {
      document.getElementById(`${name}-${key - 1}`).focus();
    }
  };

  const setField = (event, key) => {
    if (event.target.value.length >= maxLength && key < size - 1) {
      document.getElementById(`${name}-${key + 1}`).focus();
    }
  };

  const change = (event, key) => {
    const data = { ...token, [event.target.name]: event.target.value };
    setToken(data);
    onChange(Object.values(data).toString().replace(/,/g, "").trim());
    setField(event, key);
  };

  return inputs.map((indexValue, key) => (
    <Input2faStyle
      data-testid="input2fa"
      id={`${name}-${key}`}
      type={type}
      name={`${name}-${key}`}
      value={token[`${name}-${key}`]}
      onKeyDown={(event) => {
        if (event.key === "Backspace") cleanField(event, key);
      }}
      onChange={(event) => change(event, key)}
      key={key}
      maxLength={maxLength}
      autoComplete="off"
      style={{ marginRight: centerValue === key && "20px" }}
      width={width}
      className={type}
      {...attrs}
    />
  ));
};

Input2fa.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  size: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  width: PropTypes.any,
};

Input2fa.defaultProps = {
  type: "tel",
  name: "token",
  value: "",
  size: 6,
  onChange: () => {},
  maxLength: 1,
  width: "",
};

export default Input2fa;
