import styled from "styled-components";
import css from "@styled-system/css";
import { compose, layout } from "styled-system";
import { SearchButtonIcon } from "~svgs";

import theme from "~tokens";

export const Container = styled("div")(
  css({
    position: "relative",
    svg: {
      position: "relative",
      zIndex: "1",
      margin: "0 -26px -6px -6px",
    },
  }),
);

export const InputStyle = styled("input")(
  css({
    width: "100%",
    height: "34px",
    background: `url(${SearchButtonIcon})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: "white",
    backgroundPosition: "4px",
    border: `1px solid ${theme.colors.gray200}`,
    borderRadius: "4px",
    paddingLeft: "32px",
    paddingRight: "16px",
    "&:focus": {
      border: `1px solid ${theme.colors.accentPrimaryDark}`,
      boxShadow: `0px 0px 0px 2px ${theme.colors.accentPrimaryLight}`,
    },
  }),
  compose(layout),
);

export const HeaderSearchStyle = styled("li")(
  css({
    width: "100%",
    background: theme.colors.accentPrimaryLight,
    padding: "8px",
  }),
  compose(layout),
);

export const HeaderBreadCrumb = styled("div")(
  css({
    width: "100%",
    color: theme.colors.accentPrimaryDark,
    display: "inline-flex",
    "& > span": {
      fontSize: "12px",
      margin: "4px",
      opacity: "0.5",
    },
  }),
  compose(layout),
);

export const HeaderContent = styled("div")(
  css({
    display: "flex",
    "& > svg": {
      margin: "0",
    },
    "& > span": {
      marginLeft: "8px",
      top: "4px",
      position: "relative",
      color: theme.colors.accentPrimaryDark,
    },
  }),
  compose(layout),
);

export const SearchLabel = styled("li")(
  css({
    color: theme.colors.gray300,
    background: theme.colors.grayWhite,
    textTransform: "uppercase",
    padding: "20px",
    fontSize: "12px",
  }),
  compose(layout),
);

export const SearchIconStyle = styled("div")(
  css({
    marginLeft: "8px",
    width: "24px",
    marginRight: "8px",
    display: "inline-block",
    "& > svg": {
      marginLeft: "0",
      position: "relative",
    },
  }),
  compose(layout),
);
