/* eslint-disable implicit-arrow-linebreak */
import React from "react";
import PropTypes from "prop-types";
import TableDefault from "./TableDefault/TableDefault";

const TableComponent = ({
  columns,
  data,
  onUpdateTable,
  tableAttrs,
  sortBy,
  width,
  height,
  renderRowSubComponent,
  footer,
  footerAttrs,
  tableType,
  ...attrs
}) => {
  const props = {
    columns,
    data,
    onUpdateTable,
    tableAttrs,
    sortBy,
    width,
    height,
    renderRowSubComponent,
    footer,
    footerAttrs,
    tableType,
    ...attrs,
  };

  return <TableDefault {...props} />;
};

TableComponent.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  onUpdateTable: PropTypes.func,
  tableAttrs: PropTypes.object,
  renderRowSubComponent: PropTypes.func,
  footerAttrs: PropTypes.object,
  sortBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool,
    }),
  ),
  width: PropTypes.string,
  height: PropTypes.string,
  footer: PropTypes.bool,
  tableType: PropTypes.string,
};

TableComponent.defaultProps = {
  onUpdateTable: () => {},
  columns: [],
  data: [],
  tableAttrs: {},
  footerAttrs: {},
  sortBy: [],
  width: "100%",
  height: "auto",
  renderRowSubComponent: () => {},
  footer: true,
  tableType: "default",
};

export default TableComponent;
