import React from "react";
import PropTypes from "prop-types";
import { GroupContent } from "./ButtonGroupStyle";

const ButtonGroup = ({ children, secondary, radioType }) => (
  <GroupContent
    className={[radioType && "radio-type", secondary && "secondary"]}
    data-testid="button-group"
  >
    {children}
  </GroupContent>
);

ButtonGroup.propTypes = {
  secondary: PropTypes.bool,
  children: PropTypes.node,
  radioType: PropTypes.bool,
};

ButtonGroup.defaultProps = {
  secondary: false,
  children: "",
  radioType: false,
};

export default ButtonGroup;
