import styled from "styled-components";
import css from "@styled-system/css";
import { compose, space, flexbox, layout, variant } from "styled-system";
import theme from "~tokens";

export const AccordionStyle = styled("div")(
  css({
    display: "flex",
    flexDirection: "column",
    bg: theme.colors.grayWhite,
    borderRadius: "4px",
    width: "289px",
    transition: "all .15s ease-out",
    cursor: "pointer",
    "&:hover, &:focus, &:focus-within": {
      boxShadow: theme.effects.cardShadow,
    },
  }),
  compose(space, flexbox, layout),
);
export const AccordionTitle = styled("p")(
  css({
    display: "grid",
    gridTemplateColumns: " 1fr auto",
    font: theme.fonts.textRegularMedium,
    borderBottom: "1px solid",
    borderColor: theme.colors.gray200,
    color: theme.colors.accentPrimaryDark,
    padding: "16px",
    alignItems: "center",
    margin: "0",
    "&:hover": { opacity: "0.8" },
    svg: {
      ":hover": { opacity: "0.5" },
      path: {
        stroke: theme.colors.accentPrimaryDark,
      },
    },
  }),
  variant({
    variants: {
      open: {
        svg: {
          transform: "rotate(180deg) ",
        },
      },
    },
  }),
);

export const BodyAccordion = styled("div")(
  css({
    font: theme.fonts.textRegularSmall,
    color: theme.colors.gray300,
    padding: "0px 16px",
    height: "0px",
    overflow: "hidden",
    transition: "all 0.3s",
  }),
  variant({
    variants: {
      open: {
        display: "block",
        height: "auto",
        overflow: "visible",
        padding: "12px 16px",
      },
      noTitle: {
        height: "auto",
        padding: "12px 16px",
      },
    },
  }),
);
