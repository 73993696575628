import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ChevronRightIcon } from "~svgs";

import { Item } from "./BreadcrumbStyle";

const BreadcrumbItem = ({
  className, active, tag, children, ...attrs
}) => {
  const classes = classNames(className, active && "active", "breadcrumb-item");
  return (
    <Fragment>
      <ChevronRightIcon />
      <Item {...attrs} as={tag} className={classes} aria-current={active ? "page" : undefined}>
        {children}
      </Item>
    </Fragment>
  );
};

BreadcrumbItem.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.node,
};

BreadcrumbItem.defaultProps = {
  className: "",
  active: false,
  tag: "a",
  children: "",
};

export default BreadcrumbItem;
