import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import Tab from "./Tab";
import { ContentTabs, ContentTab, Ol } from "./TabsStyle";

const Tabs = ({ type, bordeLine, children, position, onChange, activeColor, ...attrs }) => {
  const [activeTab, setActiveTab] = useState();

  useEffect(() => {
    const active = children.filter((child) => child.props?.active);
    if (active.length > 0) {
      setActiveTab(active[0].props.label);
    } else {
      setActiveTab(children[0].props.label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onChange(activeTab);
  }, [activeTab, onChange]);

  return (
    <ContentTabs id="tabs" data-testid="tabs-test" {...attrs}>
      {children.length > 0 && (
        <Fragment>
          <Ol variant={[position]} activeColor={activeColor}>
            {children.map((child) => {
              const { label, disabled, active, icon, width, ...attrss } = child.props;

              return (
                <Tab
                  type={type}
                  variant={[disabled && "disabled"]}
                  activeTab={activeTab}
                  key={label}
                  icon={icon}
                  label={label}
                  width={width}
                  bordeLine={bordeLine}
                  activeColor={activeColor}
                  {...attrss}
                  onClick={() => !disabled && setActiveTab(label)}
                />
              );
            })}
          </Ol>
          <ContentTab>
            {children.map((child) => {
              if (child.props.label !== activeTab) return null;
              return child.props.children;
            })}
          </ContentTab>
        </Fragment>
      )}
    </ContentTabs>
  );
};

Tabs.propTypes = {
  bordeLine: PropTypes.bool,
  children: PropTypes.node,
  position: PropTypes.string,
  type: PropTypes.oneOf(["text", "icon"]),
  onChange: PropTypes.func,
  activeColor: PropTypes.string,
};

Tabs.defaultProps = {
  bordeLine: false,
  children: "",
  position: "start",
  type: "text",
  onChange: () => {},
  activeColor: "primaryDark",
};

export default Tabs;
