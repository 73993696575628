import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { FlexStyle } from "./FlexStyle";

const Flex = forwardRef(({ children, spaceChildren, flexDirection, ...attrs }, ref) => (
  <FlexStyle
    ref={ref}
    spaceChildren={spaceChildren}
    flexDirection={flexDirection}
    {...attrs}
    data-testid="flex"
  >
    {children}
  </FlexStyle>
));

Flex.propTypes = {
  children: PropTypes.node,
  spaceChildren: PropTypes.number,
  flexDirection: PropTypes.string,
};

Flex.defaultProps = {
  children: "",
  spaceChildren: null,
  flexDirection: "",
};

export default Flex;
