import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Logo, MenuIcon } from "~svgs";
import Icon from "~core/icon";
import { MenuHeaderStyle } from "./MenuHeaderStyle";

const MenuHeader = ({ children, logo, brandTitle, onClick, ...attrs }) => (
  <MenuHeaderStyle {...attrs}>
    {!logo && Object.keys(logo).length === 0 ? (
      <Fragment>
        <Icon size={40} src={Logo} />
        <span>{brandTitle}</span>
      </Fragment>
    ) : (
      <Fragment>
        <Icon size={64} src={logo} />
        <span>{brandTitle}</span>
      </Fragment>
    )}
    <button type="button" onClick={onClick}>
      <Icon size={34} src={MenuIcon} />
    </button>
    {children}
  </MenuHeaderStyle>
);

MenuHeader.propTypes = {
  children: PropTypes.node,
  logo: PropTypes.any,
  brandTitle: PropTypes.string,
  onClick: PropTypes.func,
};

MenuHeader.defaultProps = {
  children: "",
  logo: "",
  brandTitle: "Centro de \n Inteligência",
  onClick: () => {},
};

export default MenuHeader;
