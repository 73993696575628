import styled from "styled-components";
import css from "@styled-system/css";
import theme from "~tokens";
// eslint-disable-next-line import/extensions
import "../../../global.css";

export const NotificationContainer = styled("ul")(
  css({
    listStyle: "none",
    position: "fixed",
    top: "37px",
    right: "10px",
    display: "grid",
    maxWidth: "300px",
    height: "0",
  }),
);

export const NotificationContent = styled("li")(
  css({
    transition: "0.4s",
    position: "relative",
    display: "flex",
    right: "-110%",
    opacity: "0",
    minWidth: "300px",
    background: theme.colors.grayWhite,
    borderRadius: "4px",
    boxShadow: "0px 2px 6px 1px rgba(0,0,0,16%)",
    boxSizing: "border-box",
    "&.open": {
      right: "10px",
      opacity: "1",
      transition: "0.4s",
    },
  }),
);

export const NotificationItem = styled("div")(
  css({
    padding: "8px",
    paddingLeft: "16px",
    "&.logo": {
      justifyContent: "center",
      background: "#F5F7FA",
      borderRadius: "4px 0px 0px 4px",
      padding: "8px",
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    "& > p": {
      color: theme.colors.gray400,
    },
  }),
);

export const NotificationHeader = styled("div")(
  css({
    padding: "0px",
    marginBottom: "8px",
    marginRight: "26px",
    "& > h1": {
      font: theme.fonts.textBoldLarge,
      color: theme.colors.gray500,
    },
  }),
);

export const CloseStyle = styled("div")(
  css({
    position: "absolute",
    right: "10px",
    background: "transparent",
    border: "0",
    top: "7px",
    cursor: "pointer",
    ":hover": {
      cursor: "pointer",
      opacity: "0.5",
    },
  }),
);

export const FooterNotification = styled("span")(
  css({
    font: theme.fonts.textBoldSmall,
    color: theme.colors.gray400,
    position: "relative",
    marginTop: "16px",
    marginBottom: "8px",
    display: "block",
  }),
);
