import styled from "styled-components";
import css from "@styled-system/css";
import { compose, layout } from "styled-system";
import { SearchButtonIcon, SearchButtonPlaceholderIcon } from "~svgs";
import theme from "~tokens";

export const Container = styled("div")(
  css({
    svg: {
      position: "relative",
      zIndex: "1",
      margin: "0 -29px -7px -6px",
    },
  }),
);

export const InputStyle = styled("input")(
  css({
    zIndex: 2,
    maxWidth: "12%",
    height: "34px",
    background: "transparent",
    backgroundPositionX: "4px",
    color: "transparent",
    border: "none",
    padding: "5px",
    transition: ".3s all",
    "::-webkit-input-placeholder ": {
      color: "transparent",
    },
    ":-ms-input-placeholder": {
      color: "transparent",
    },
    "::placeholder": {
      color: "transparent",
    },
  }),
  ({ open, isEmpty }) => open
    && css({
      maxWidth: "100%",
      color: "black",
      backgroundColor: "white",
      borderRadius: "4px",
      paddingLeft: "32px",
      border: `1px solid ${theme.colors.accentPrimaryDark}`,
      "&:focus": {
        background: `url(${
          isEmpty ? SearchButtonPlaceholderIcon : SearchButtonIcon
        }) no-repeat center left`,
        backgroundPositionX: "4px",
        backgroundColor: "white",
        boxShadow: `0px 0px 0px 2px ${theme.colors.accentPrimaryLight}`,
      },
      "::-webkit-input-placeholder ": {
        color: theme.colors.gray300,
      },
      "::-webkit-input-placeholder": {
        color: theme.colors.gray300,
        transitionProperty: "opacity. transform",
        transitionDuration: ".3s",
        transitionTimingFunction: "cubic-bezier(.5, 0, 0, 1)",
      },
      ":-ms-input-placeholder": {
        color: theme.colors.gray300,
        transitionProperty: "opacity. transform",
        transitionDuration: ".3s",
        transitionTimingFunction: "cubic-bezier(.5, 0, 0, 1)",
      },
      "&::placeholder": {
        color: theme.colors.gray300,
        transitionProperty: "opacity. transform",
        transitionDuration: ".3s",
        transitionTimingFunction: "cubic-bezier(.5, 0, 0, 1)",
      },
    }),
  compose(layout),
);
