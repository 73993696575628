import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { CloseIcon, HideMenuIcon, ShowMenuIcon } from "~svgs";
import MenuHeader from "./MenuHeader";
import MenuWrapper from "./MenuWrapper";
import { MenuStyle, Span } from "./MenuStyle";
import MenuContext from "./MenuContext";

const Menu = ({ children, logo, brandTitle, collapse, position, navPosition, ...attrs }) => {
  const [isCollapse, setCollapse] = useState(collapse);
  const toggleCollapse = () => {
    setCollapse(!isCollapse);
  };

  const closeMenu = () => {
    const menuDiv = document.getElementById("vl-menu");
    const bgOpacity = document.getElementById("vl-bg-opacity");
    menuDiv.classList.remove("show");
    bgOpacity.classList.remove("show");
  };

  const menuStyle = isCollapse ? "collapse" : "expanded";

  useEffect(() => {
    const profile = document.getElementById("profile");
    if (profile) {
      profile.addEventListener("click", () => {
        setCollapse(false);
      });
    }
  });

  return (
    <MenuContext.Provider value={{ toggleCollapse, isCollapse, closeMenu }}>
      <MenuWrapper
        data-testid="menu"
        logo={logo}
        brandTitle={brandTitle}
        id="menu-wrapper"
        className={menuStyle}
        position={position}
      >
        <MenuStyle id="menu-container" className={menuStyle} navPosition={navPosition} {...attrs}>
          <MenuHeader
            id="menu-header"
            className={clsx("header", menuStyle)}
            logo={logo}
            brandTitle={brandTitle}
          >
            <h2>Menu</h2>
            <Span onClick={() => closeMenu()}>
              <CloseIcon />
            </Span>
          </MenuHeader>
          {children}
          <button
            type="button"
            id="hide-menu"
            className="hide-menu"
            onClick={() => toggleCollapse()}
          >
            {menuStyle !== "collapse" ? <HideMenuIcon /> : <ShowMenuIcon />}
            <span>Esconder Menu</span>
          </button>
        </MenuStyle>
      </MenuWrapper>
    </MenuContext.Provider>
  );
};

Menu.propTypes = {
  children: PropTypes.node,
  logo: PropTypes.any,
  brandTitle: PropTypes.string,
  collapse: PropTypes.bool,
  position: PropTypes.string,
  navPosition: PropTypes.string,
};

Menu.defaultProps = {
  children: "",
  logo: "",
  brandTitle: "Centro de \n Inteligência",
  collapse: false,
  position: "relative",
  navPosition: "relative",
};

export default Menu;
