import styled from "styled-components";
import css from "@styled-system/css";
import { compose, space, flexbox, layout } from "styled-system";
import theme from "~tokens";

const { xs, md } = theme.media;

export const MenuWrapperStyle = styled("div")(
  ({ position }) => css({
    position,
  }),
  css({
    transition: "0.6s",
    background: theme.colors.grayWhite,
    width: "224px",
    "& > div#vl-menu > nav > button.hide-menu": {
      alignItems: "center",
      width: "79%",
      paddingLeft: "7px",
      margin: "0",
      [xs]: {
        display: "none",
      },
    },
    "&.collapse": {
      width: "80px",
      transition: "0.6s",
      [xs]: {
        width: "100vw",
      },
      "& > div#vl-menu > nav > button.hide-menu": {
        width: "79%",
        paddingLeft: "7px",
        "& > span": {
          display: "none",
        },
        "& > svg": {
          margin: "0",
        },
      },
    },
    "& > div#vl-menu > div.tooltip": {
      display: "block",
      width: "100%",
      [xs]: {
        display: "none",
      },
    },
    [md]: {
      width: "224px",
      "& > div#vl-menu": {
        width: "224px",
      },
    },
    [xs]: {
      height: "0px",
      width: "100vw",
      "& > div#vl-menu": {
        width: "100vw",
      },
    },
    "& > nav": {
      [xs]: {
        transition: "0.2s",
      },
    },
    "& > div": {
      height: "0",
      visibility: "hidden",
      "& > svg": {
        visibility: "hidden",
        [xs]: {
          visibility: "visible",
        },
      },
      [xs]: {
        visibility: "visible",
        height: "auto",
        minHeight: "55px",
        position: "relative",
        top: "0",
        width: "100%",
      },
    },
    "& > div.menu": {
      position: "relative",
      visibility: "visible",
      height: "100vh",
      width: "100%",
      "&.show > nav": {
        right: "0px",
      },
      [xs]: {
        display: "none",
        "&.show": {
          display: "block",
        },
      },
    },
    "& > div > nav > button.hide-menu": {
      position: "absolute",
      width: "79%",
      bottom: "15px",
      border: "0px",
      background: "transparent",
      display: "flex",
      cursor: "pointer",
      paddingLeft: "7px",
      "& > svg": {
        minWidth: "24px",
        margin: "0",
      },
      "& > span": {
        color: theme.colors.gray400,
        font: theme.fonts.textRegularMedium,
        position: "relative",
        paddingLeft: "8px",
        [md]: {
          display: "none",
        },
        [xs]: {
          display: "inline-block",
        },
      },
    },
    "& > div > button > div.tooltip": {
      display: "none",
      [md]: {
        display: "block",
      },
      [xs]: {
        display: "none",
      },
    },
    "& > div > button.hide": {
      display: "none",
    },
  }),
  compose(space, flexbox, layout),
);
