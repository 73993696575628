import React from "react";
import PropTypes from "prop-types";
import { VideoTag } from "./MediaCardStyle";

const Video = ({ children, ...attrs }) => <VideoTag {...attrs}>{children}</VideoTag>;

Video.propTypes = {
  children: PropTypes.node,
  controls: PropTypes.bool,
};

Video.defaultProps = {
  children: "",
  controls: false,
};

export default Video;
