import styled from "styled-components";
import css from "@styled-system/css";
import { compose, space, flexbox, layout } from "styled-system";
import theme from "~tokens";

export const MenuListStyle = styled("ul")(
  css({
    position: "relative",
    bg: theme.colors.grayWhite,
    listStyle: "none",
    boxSizing: "border-box",
    marginTop: "16px",
    borderTop: "1px solid #ecebeb",
    paddingTop: "8px",
    height: "auto",
    maxHeight: "100vh",
    "&.sub-list": {
      display: "none",
      marginTop: "0",
      borderTop: "0",
      height: "100%",
      paddingTop: "0",
      "& > li": {
        padding: "12px",
        paddingLeft: "28px",
        paddingRight: "10px",
        "& > span": {
          top: "0",
        },
      },
    },
  }),
  compose(space, flexbox, layout),
);
