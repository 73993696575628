import React from "react";
import PropTypes from "prop-types";
import { ErrorContainerStyle, ErrorSvgStyle, ErrorCodeStyle, CustomContainer } from "./ErrorStyle";
import { NotFoundImage, ErrorImage, Logo } from "~svgs";
import Heading from "~typography/heading";

const ErrorPainel = ({ children, code, customIcon, ...attrs }) => (
  <ErrorContainerStyle {...attrs}>
    <Logo />
    {customIcon ? (
      <CustomContainer>{customIcon()}</CustomContainer>
    ) : (
      <ErrorSvgStyle>{code === 404 ? <NotFoundImage /> : <ErrorImage />}</ErrorSvgStyle>
    )}
    <ErrorCodeStyle>
      <Heading tag="span">Error {code}</Heading>
    </ErrorCodeStyle>
    <div className="content">{children}</div>
  </ErrorContainerStyle>
);

ErrorPainel.propTypes = {
  children: PropTypes.node,
  code: PropTypes.number,
  customIcon: PropTypes.node,
};

ErrorPainel.defaultProps = {
  children: "",
  code: "",
  customIcon: "",
};

export default ErrorPainel;
