import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CheckboxIcon, RemoveIcon } from "~svgs";
import Icon from "~core/icon";
import Label from "~form/label";
import { CheckboxContainer, CheckboxStyle } from "./CheckboxStyle";

export const CheckBox = ({
  id,
  checked,
  partiallyChecked,
  onChange,
  group,
  disabled,
  label,
  innerRef,
  labelStyle,
  ...attrs
}) => {
  const [isChecked, setChecked] = useState(checked);
  const [isPartiallyChecked, setPartiallyChecked] = useState(partiallyChecked);
  const callAction = (e) => {
    setChecked(!isChecked);
    if (onChange) onChange(e);
    return e;
  };

  useEffect(() => {
    setChecked(checked);
    setPartiallyChecked(partiallyChecked && !checked);
  }, [checked, partiallyChecked]);

  return (
    <CheckboxContainer
      {...attrs}
      htmlFor={id}
      onClick={(e) => e.stopPropagation()}
      id="checkbox"
      data-testid="checkbox"
    >
      <CheckboxStyle>
        <input
          id={id}
          onChange={(e) => !disabled && callAction(e)}
          name={group}
          checked={isChecked}
          disabled={disabled}
          type="checkbox"
          ref={innerRef}
        />
        {isChecked && !partiallyChecked && <Icon src={CheckboxIcon} className="checked-icon" />}
        {isPartiallyChecked && !checked && (
          <Icon src={RemoveIcon} className="checked-icon partial" />
        )}
      </CheckboxStyle>
      <Label htmlFor={id} style={labelStyle}>
        {label}
      </Label>
    </CheckboxContainer>
  );
};

CheckBox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  checked: PropTypes.bool,
  partiallyChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  group: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  labelStyle: PropTypes.object,
};

CheckBox.defaultProps = {
  disabled: false,
  checked: false,
  partiallyChecked: false,
  innerRef: () => {},
  labelStyle: {},
};

export default CheckBox;
