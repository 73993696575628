import React, { useRef } from "react";
import PropTypes from "prop-types";
import {
  ChatFieldContainer, FiledStyle, SendButton, AttachButton
} from "./ChatFieldStyle";
import { SendIcon, AttachIcon } from "~svgs";
import Icon from "~core/icon";

const ChatField = ({
  rootStyle, onClick, onFileHandle, ...attrs
}) => {
  const fileField = useRef();
  return (
    <ChatFieldContainer className={rootStyle}>
      <input type="file" style={{ display: "none" }} ref={fileField} onChange={onFileHandle} />
      <AttachButton type="button" onClick={() => fileField.current.click()}>
        <Icon src={AttachIcon} />
      </AttachButton>
      <FiledStyle {...attrs} />
      <SendButton type="button" onClick={onClick}>
        <Icon src={SendIcon} />
      </SendButton>
    </ChatFieldContainer>
  );
};

ChatField.propTypes = {
  rootStyle: PropTypes.any,
  onClick: PropTypes.func,
  onFileHandle: PropTypes.func,
};

ChatField.defaultProps = {
  rootStyle: "",
  onClick: () => {},
  onFileHandle: () => {},
};

export default ChatField;
