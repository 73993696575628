import styled from "styled-components";
import css from "@styled-system/css";
import theme from "~tokens";

export const TextSpan = styled("span")(
  css({
    display: "inline-flex",
    height: "auto",
    width: "auto",
    alignItems: "center",
    wordBreak: "break-word",
  }),
  ({ align }) => (align ? `justify-content: flex-${align};` : "justify-content: flex-start;"),
);

export const Prefix = styled("span")(
  css({
    font: theme.fonts.textRegularSmall,
    color: theme.colors.gray300,
    lineHeight: "20px",
    marginRight: "10px",
  }),
);

export const Suffix = styled("span")(
  css({
    font: theme.fonts.textRegularSmall,
    color: theme.colors.gray300,
    lineHeight: "20px",
    marginLeft: "10px",
  }),
);
