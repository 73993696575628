import styled, { keyframes } from "styled-components";
import css from "@styled-system/css";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const LoadingStyle = styled("div")(
  css({
    zIndex: 9999,
    display: "grid",
    alignContent: "center",
    justifyContent: "center",
    top: 0,
    left: 0,
    backgroundColor: "white",
    position: "absolute",
  }),
  ({ fitParent }) => (fitParent ? "height: 100%; width: 100%;" : "height: 100vh; width: 100vw;"),
);

export const Container = styled("div")(
  css({
    display: "inherit",
    justifyItems: "center",
  }),
);

export const RotateContainerLoading = styled.div`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  animation: ${rotate360} 2s linear infinite;
`;
