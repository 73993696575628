import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  ToastContentStyle,
  ToastHeader,
  ToastItemStyle,
  CloseStyle,
  ToastChildren,
} from "./ToastStyle";
import Heading from "~typography/heading";
import Icon from "~core/icon";
import Flex from "~core/flex";
import { CloseIcon, SuccessIcon, ErrorIcon, WarningIcon } from "~svgs";

const Toast = ({
  children,
  error,
  warning,
  success,
  isOpen,
  title,
  autoClose,
  delay,
  onClose,
  ...attrs
}) => {
  useEffect(() => {
    const interval = isOpen && autoClose && setTimeout(onClose, delay);
    return () => isOpen && autoClose && clearTimeout(interval);
  }, [isOpen, autoClose, delay, onClose]);
  const toastType = (error && "error") || (success && "success") || (warning && "warning");
  const toastIcons = {
    error: ErrorIcon,
    success: SuccessIcon,
    warning: WarningIcon,
  };
  return (
    <ToastItemStyle className={isOpen && "open"} data-testid="toast">
      <ToastContentStyle {...attrs} className={toastType}>
        <Flex flexDirection="row" alignItems="flex-start" justifyContent="flex-start" width="100%">
          {toastType && <Icon style={{ marginRight: "12px" }} src={toastIcons[toastType]} />}
          <Flex
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            width="100%"
          >
            <ToastHeader>
              <Heading size={16}>{title}</Heading>
              <CloseStyle onClick={onClose}>
                <CloseIcon />
              </CloseStyle>
            </ToastHeader>
            <ToastChildren>{children}</ToastChildren>
          </Flex>
        </Flex>
      </ToastContentStyle>
    </ToastItemStyle>
  );
};

Toast.propTypes = {
  children: PropTypes.node,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  success: PropTypes.bool,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  autoClose: PropTypes.bool,
  delay: PropTypes.number,
};

Toast.defaultProps = {
  children: "",
  error: false,
  warning: false,
  success: false,
  onClose: () => {},
  isOpen: false,
  title: "",
  autoClose: false,
  delay: 3000,
};

export default Toast;
