import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ReactComponent as CloseIcon } from "~svgs/close.svg";
import Heading from "~typography/heading";
import ModalContent from "~core/modal/ModalContent";
import ModalFooter from "~core/modal/ModalFooter";
import { ModalOverlay, ModalWrapper, ModalStyle, Header, Close } from "./ModalStyle";

const Modal = ({ isOpen, title, onClose, children, actions, width, ...attrs }) => {
  const [isShowing, setIsShowing] = useState(isOpen);

  useEffect(() => setIsShowing(isOpen), [isOpen]);

  const toggle = () => {
    onClose(!isShowing);
    setIsShowing(!isShowing);
  };

  return (
    isShowing && (
      <ModalOverlay id="modal" data-testid="modal">
        <ModalWrapper>
          <ModalStyle width={width} {...attrs}>
            <Header>
              <Heading tag="h5">{title}</Heading>
              <Close style={{ transform: "translateY(1.5px)" }} onClick={toggle}>
                <CloseIcon />
              </Close>
            </Header>
            <ModalContent>{children}</ModalContent>
            {actions && <ModalFooter>{actions}</ModalFooter>}
          </ModalStyle>
        </ModalWrapper>
      </ModalOverlay>
    )
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  actions: PropTypes.node,
  width: PropTypes.string,
};

Modal.defaultProps = {
  isOpen: false,
  title: "",
  children: "",
  onClose: () => {},
  actions: "",
  width: "480px",
};

export default Modal;
