import React from "react";
import PropTypes from "prop-types";
import { ToastContainerStyle } from "./ToastStyle";

const ToastContainer = ({ children, ...attrs }) => (
  <ToastContainerStyle {...attrs}>{children}</ToastContainerStyle>
);

ToastContainer.propTypes = {
  children: PropTypes.node,
};

ToastContainer.defaultProps = {
  children: "",
};

export default ToastContainer;
