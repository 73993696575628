import styled from "styled-components";
import css from "@styled-system/css";
import theme from "~tokens";

export const DialogOverlay = styled("div")(
  css({
    position: " fixed",
    top: " 0",
    left: " 0",
    zIndex: " 1040",
    width: " 100vw",
    height: " 100vh",
    backgroundColor: "rgba(0,0,0,0.5)",
  }),
);

export const DialogWrapper = styled("div")(
  css({
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "1050",
    width: " 100%",
    height: " 100%",
    overflowX: "hidden",
    overflowY: "auto",
    outline: "0",
    p: 4,
  }),
);

export const DialogStyle = styled("div")(
  css({
    zIndex: "100",
    background: "white",
    position: "relative",
    // margin: "1.75rem auto",
    borderRadius: "8px",
    maxWidth: "360px",
    padding: "16px",
    display: "grid",
    alignItems: "center",
    "&.dialog-box": {
      maxWidth: "352px",
    },
  }),
);

export const DialogContentStyle = styled("div")(
  css({
    alignSelf: "end",
    "&.dialog-box-container": {
      paddingTop: "0",
      marginTop: "8px",
      minHeight: "auto",
      "& > p": {
        color: theme.colors.gray400,
        font: theme.fonts.textRegularMedium,
        display: "block",
      },
    },
  }),
);

export const DialogFooterStyle = styled("div")(
  css({
    display: "flex",
    flexDirection: "column",
    button: {
      marginLeft: "20px",
    },
  }),
);

export const Header = styled("div")(
  css({
    display: "grid",
    gridTemplateColumns: "1fr 0.1fr",
    alignItems: "center",
    "&.dialog-box": {
      display: "flex",
      position: "relative",
      "& > div": {
        position: "absolute",
        right: "0",
      },
      "& > svg": {
        marginRight: "8px",
      },
    },
  }),
);

export const Close = styled("div")(
  css({
    margin: "0 0 0 auto",
    ":hover": {
      cursor: "pointer",
      opacity: "0.5",
    },
  }),
);
