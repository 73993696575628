import React, { Fragment } from "react";
import PropTypes from "prop-types";

const Source = ({ src, ...attrs }) => (
  <Fragment>
    <source src={src} {...attrs} />
  </Fragment>
);

Source.propTypes = {
  src: PropTypes.string,
};

Source.defaultProps = {
  src: "",
};

export default Source;
