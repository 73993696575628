import React, { useState } from "react";
import PropTypes, { string } from "prop-types";
import OpacityDiv from "~core/bg/OpacityDiv";
import { MenuWrapperStyle } from "./MenuWrapperStyle";
import MenuHeader from "./MenuHeader";

const MenuWrapper = ({ children, brandTitle, logo, position, ...attrs }) => {
  const [show, setShow] = useState(false);

  const closeMenu = () => {
    setShow(!show);
  };

  return (
    <MenuWrapperStyle position={position} {...attrs}>
      <MenuHeader logo={logo} brandTitle={brandTitle} onClick={() => closeMenu()} />
      <div id="vl-menu" className={`menu ${show ? "show" : ""}`}>
        {children}
      </div>
      <OpacityDiv id="vl-bg-opacity" show={show} onClick={() => closeMenu()} />
    </MenuWrapperStyle>
  );
};

MenuWrapper.propTypes = {
  logo: PropTypes.any,
  brandTitle: PropTypes.string,
  children: PropTypes.node,
  position: string,
};

MenuWrapper.defaultProps = {
  logo: "",
  brandTitle: "Centro de \n Inteligência",
  children: "",
  position: "relative",
};

export default MenuWrapper;
