import React from "react";
import PropTypes from "prop-types";
import { ModalContentStyle } from "./ModalStyle";
import Divider from "~core/divider";

const ModalContent = ({ children, ...attrs }) => (
  <ModalContentStyle {...attrs}>
    <Divider style={{ marginBottom: "16px", marginTop: "16px" }} position="center" />
    {children}
  </ModalContentStyle>
);

ModalContent.propTypes = {
  children: PropTypes.node,
  divider: PropTypes.bool,
};

ModalContent.defaultProps = {
  children: "",
  divider: false,
};
export default ModalContent;
