import styled from "styled-components";
import css from "@styled-system/css";
import { compose, color, typography, space } from "styled-system";
import theme from "~tokens";

export const LinkStyle = styled("button")(
  css({
    border: "none",
    display: "flex",
    font: theme.fonts.textRegularSmall,
    color: theme.colors.accentPrimaryDark,
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
    },
    "&:active, &:visited, &:focus": {
      textDecoration: "none",
    },
  }),
  compose(color, space, typography),
);
export const LinkName = styled("span")(
  css({
    "&::after": {
      content: '""',
      display: "block",
      borderBottom: `2px solid ${theme.colors.accentPrimaryLight}`,
      position: "relative",
      width: 0,
      left: 0,
      transition: "all 0.5s ease",
    },
    "&:hover::after": {
      width: "100%",
    },
  }),
  compose(color, space, typography),
);
