/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { ArrowButton } from "./ArrowStyle";
import { ChevronLeftIcon, ChevronRightIcon } from "~svgs";

const Arrow = ({ left, onClick }) => (
  <ArrowButton type="button" onClick={() => onClick()} left={left}>
    {left ? <ChevronLeftIcon /> : <ChevronRightIcon />}
  </ArrowButton>
);

Arrow.propTypes = {
  left: PropTypes.bool,
};

Arrow.defaultProps = {
  left: false,
};

export default Arrow;
