import React from "react";
import PropTypes from "prop-types";
import Icon from "~core/icon";
import { Li, Span } from "./TabsStyle";

const Tab = ({
  type,
  activeTab,
  label,
  onClick,
  icon,
  width,
  bordeLine,
  variant,
  activeColor,
  ...attrs
}) => {
  let className = "";
  if (activeTab === label) {
    className = "tab-list-active";
  }

  const tabTitleContent = {
    text: label,
    icon: <Icon src={icon} alt={label} mr={2} />,
  };

  return (
    <Li
      {...attrs}
      variant={[variant, bordeLine && "bordeLine", className && "active"]}
      className={className}
      width={width}
      onClick={() => onClick(label)}
      activeColor={activeColor}
    >
      <Span>{tabTitleContent[type]}</Span>
    </Li>
  );
};

Tab.propTypes = {
  variant: PropTypes.array,
  activeTab: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.any,
  bordeLine: PropTypes.bool,
  width: PropTypes.string,
  type: PropTypes.oneOf(["text", "icon"]),
  activeColor: PropTypes.string,
};

Tab.defaultProps = {
  variant: [],
  activeTab: "",
  label: "tab",
  onClick: () => {},
  icon: "",
  bordeLine: false,
  width: "auto",
  type: "text",
  activeColor: "primaryDark",
};

export default Tab;
