import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CloseIcon } from "~svgs";
import { Container, ItemContainer, CloseButton } from "./LightboxStyle";

const LightBox = ({ open, children, ...attrs }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Container id="lightbox" data-testid="lightbox" open={isOpen} {...attrs}>
      <CloseButton onClick={() => setIsOpen(false)}>
        <CloseIcon />
      </CloseButton>
      <ItemContainer>{children}</ItemContainer>
    </Container>
  );
};

LightBox.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
};

LightBox.defaultProps = {
  open: true,
  children: "",
};

export default LightBox;
