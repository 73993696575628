import styled from "styled-components";
import css from "@styled-system/css";
import {
  compose, space, flexbox, layout
} from "styled-system";
import theme from "~tokens";

export const BubbleContainer = styled("div")(
  css({
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    "&.sent": {
      justifyContent: "flex-end",
    },
  }),
  compose(space, flexbox, layout),
);

export const BubbleContent = styled("div")(
  css({
    width: "80%",
    background: theme.colors.gray100,
    padding: "8px 16px 8px 16px",
    margin: "8px 16px 8px 16px",
    borderRadius: "16px 16px 16px 4px",
    color: theme.colors.gray500,
    "& > p": {
      color: theme.colors.gray500,
      fontSize: "12px",
    },
    "&.sent": {
      background: theme.colors.accentPrimaryLight,
      borderRadius: "16px 16px 4px 16px",
      "& > p": {
        color: theme.colors.accentPrimaryDark,
      },
    },
  }),
  compose(space, flexbox, layout),
);
