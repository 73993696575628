import styled from "styled-components";
import css from "@styled-system/css";
import {
  compose, space, flexbox, layout
} from "styled-system";
import theme from "~tokens";

const { md, xs } = theme.media;

export const MenuItemStyle = styled("span")(
  css({
    "&.menu-danger--content, &.menu-danger--content a": {
      color: theme.colors.red400,
      "&::after": {
        borderBottom: `2px solid ${theme.colors.red200}`,
      }
    },
  }),
);

export const MenuItemContainerStyle = styled("li")(
  css({
    borderRadius: "4px",
    font: theme.fonts.textRegularSmall,
    whiteSpace: "nowrap",
    display: "block",
    width: "auto",
    color: theme.colors.accentPrimaryDark,
    position: "relative",
    outline: "none",
    paddingBottom: "4px",
    "& > span": {
      display: "inline-block",
      cursor: "pointer",
      width: "auto",
      "& > a": {
        textDecoration: "none",
        background: "transparent",
        position: "relative",
        display: "block",
        width: "auto",
        height: "100%",
        outline: "none",
        color: theme.colors.accentPrimaryDark,
        "&:hover::after": {
          transition: "all 0.5s ease 0.3s"
        }
      },
    },
    "&:first-of-type": {
      "& > div": {
        [md]: {
          display: "block",
        },
        [xs]: {
          display: "none",
        },
      },
    },
  }),
  compose(space, flexbox, layout),
);
