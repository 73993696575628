import styled from "styled-components";
import css from "@styled-system/css";
import theme from "~tokens";

export const ScrollBarContainerStyle = styled("div")(
  css({
    zIndex: 1,
    ".vli-scrollbar-theme > .os-scrollbar": {
      padding: 0,
    },
    ".vli-scrollbar-theme > .os-scrollbar-horizontal": {
      right: 0,
      height: "8px",
    },
    ".vli-scrollbar-theme > .os-scrollbar-vertical": {
      bottom: 0,
      width: "8px",
    },
    ".vli-scrollbar-theme.os-host-rtl > .os-scrollbar-horizontal": {
      left: 0,
      right: 0,
    },
    ".vli-scrollbar-theme > .os-scrollbar-corner": {
      height: "8px",
      width: "8px",
      background: "transparent",
    },
    ".vli-scrollbar-theme > .os-scrollbar > .os-scrollbar-track, .vli-scrollbar-theme > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle": {
      background: "transparent",
    },
    ".vli-scrollbar-theme > .os-scrollbar-vertical > .os-scrollbar-track": {
      background: "transparent",
      width: "4px",
      right: "-2px",
      transform: "scaleX(1)",
      transformOrigin: "right center",
      transition: "all 0.15s ease",
      willChange: "right",
    },
    ".vli-scrollbar-theme > .os-scrollbar-vertical:not(.os-scrollbar-auto-hidden) > .os-scrollbar-track:hover": {
      right: "-4px",
      background: theme.colors.accentPrimaryLight,
      transform: "scaleX(2)",
    },
    ".vli-scrollbar-theme > .os-scrollbar-horizontal > .os-scrollbar-track": {
      background: "transparent",
      height: "4px",
      bottom: "-2px",
      transform: "scaleY(1)",
      transformOrigin: "bottom center",
      transition: "all 0.15s ease",
      willChange: "bottom",
    },
    ".vli-scrollbar-theme > .os-scrollbar-horizontal:not(.os-scrollbar-auto-hidden) > .os-scrollbar-track:hover": {
      bottom: "-4px",
      background: theme.colors.accentPrimaryLight,
      transform: "scaleY(2)",
    },
    ".vli-scrollbar-theme > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle:before": {
      content: "",
      display: "block",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      background: theme.colors.accentPrimaryDark,
      borderRadius: "1px",
      opacity: 1,
    },
    ".vli-scrollbar-theme > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle:hover:before": {
      opacity: 1,
    },
    ".vli-scrollbar-theme > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle.active:before": {
      opacity: 1,
    },
    ".vli-scrollbar-theme > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle": {
      minWidth: "8px",
      maxWidth: "48px",
    },
    ".vli-scrollbar-theme > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle": {
      minHeight: "8px",
      maxHeight: "48px",
    },
    ".vli-scrollbar-theme > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle:before": {
      height: "4px",
      bottom: 0,
      top: "auto",
    },
    ".vli-scrollbar-theme > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:before": {
      width: "4px",
      right: 0,
      left: "auto",
    },
    ".vli-scrollbar-theme.os-host-rtl > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:before": {
      left: 0,
      right: "auto",
    },
    ".vli-scrollbar-theme > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle:hover:before, .vli-scrollbar-theme > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle.active:before": {
      height: "100%",
    },
    ".vli-scrollbar-theme > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:hover:before, .vli-scrollbar-theme > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle.active:before": {
      width: "100%",
    },
    ".vli-scrollbar-theme.os-host-transition > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle:before": {
      transition: "opacity 10s, height 0.15s",
    },
    ".vli-scrollbar-theme.os-host-transition > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:before": {
      transition: "opacity 10s, width 0.15s",
    },
  }),
);
