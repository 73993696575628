import React from "react";
import PropTypes from "prop-types";
import { ModalFooterStyle } from "./ModalStyle";
import Divider from "~core/divider";
import Flex from "~core/flex";

const ModalFooter = ({ children, ...attrs }) => (
  <ModalFooterStyle {...attrs}>
    <Divider style={{ marginBottom: "16px", marginTop: "16px" }} position="center" />
    <Flex justifyContent="flex-end">{children}</Flex>
  </ModalFooterStyle>
);

ModalFooter.propTypes = {
  children: PropTypes.node,
};

ModalFooter.defaultProps = {
  children: "",
};
export default ModalFooter;
