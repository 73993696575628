import React from "react";
import PropTypes from "prop-types";
import { OmniSearchHeaderStyle } from "./OmniSearchStyle";

const OmniSearchHeaderItem = ({ children, ...attrs }) => (
  <OmniSearchHeaderStyle {...attrs}>{children}</OmniSearchHeaderStyle>
);

OmniSearchHeaderItem.propTypes = {
  children: PropTypes.node,
};

OmniSearchHeaderItem.defaultProps = {
  children: "",
};

export default OmniSearchHeaderItem;
