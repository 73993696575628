import styled from "styled-components";
import css from "@styled-system/css";
import {
  compose, space, flexbox, layout
} from "styled-system";
import theme from "~tokens";

export const CardContainer = styled("div")(
  css({
    display: "flex",
    flexFlow: "column",
    minHeight: "128px",
    width: "368px",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
    backgroundColor: theme.colors.grayWhite,
    padding: "8px",
  }),
  compose(flexbox, space, layout),
);

export const Image = styled("img")(
  css({
    maxHeight: "145px",
    maxWidth: "352px",
  }),
);

export const BodyContainer = styled("div")(
  css({
    display: "flex",
    flexFlow: "column",
    marginTop: "12px",
    marginBottom: "15px",
  }),
);

export const TextContainer = styled("div")(
  css({
    display: "flex",
    flexFlow: "row",
    position: "relative",
  }),
);

export const LinkContainer = styled("div")(
  css({
    position: "absolute",
    right: "0px",
    top: "50%",
    background: "linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)",
  }),
);

export const Title = styled("h1")(
  css({
    font: theme.fonts.textBoldLarge,
    marginBottom: "8px",
  }),
);

export const Text = styled("p")(
  css({
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    minHeight: "32px",
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
  }),
);

export const IconsContainer = styled("div")(
  css({
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: "0 0 4px 0",
    "& > svg": {
      cursor: "pointer",
      path: {
        fill: theme.colors.accentPrimaryDark,
      },
    },
  }),
);

export const HeartContainer = styled("div")(
  css({
    display: "flex",
    flexFlow: "row",
    alignItems: "flex-end",
    "& > svg": {
      cursor: "pointer",
      path: {
        fill: theme.colors.accentPrimaryDark,
      }
    },
  })
);

export const TextHeart = styled("span")(
  css({
    font: theme.fonts.textBoldLarge,
    textTransform: "uppercase",
    marginLeft: "8px",
    cursor: "pointer",
    color: theme.colors.accentPrimaryDark,
  }),
);
