import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { AvatarStyle, StatusStyle } from "./AvatarStyle";

const Avatar = ({ userName, src, status, className, color, ...attrs }) => {
  const formatName = (name) => {
    if (name && name.includes(" ") && name[name.length - 1] !== " ") {
      const newName = name.split(" ");
      return `${newName[0].substr(0, 1).toUpperCase()}${newName[newName.length - 1]
        .substr(0, 1)
        .toUpperCase()}`;
    }
    if (name) {
      return name.substr(0, 1).toUpperCase();
    }
    return "";
  };

  return (
    <AvatarStyle
      className={clsx(color, className)}
      {...attrs}
      title={userName}
      data-testid="avatar"
    >
      {src && <img src={src} alt={userName} title={userName} />}
      <StatusStyle className={clsx("status", status)} />
      {!src && <span className={color}>{formatName(userName)}</span>}
    </AvatarStyle>
  );
};

Avatar.propTypes = {
  userName: PropTypes.string,
  src: PropTypes.string,
  status: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
};

Avatar.defaultProps = {
  userName: "",
  src: "",
  status: "",
  color: "",
  className: "",
};

export default Avatar;
