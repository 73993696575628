import React from "react";
import PropTypes from "prop-types";
import { LabelStyle } from "./LabelStyle";

const Label = ({ informative, children, ...attrs }) => (
  <LabelStyle id={`label-${informative}`} data-testid={`label-${informative}`} {...attrs}>
    <span>{children}</span>
    {informative && <span className="informative">{informative}</span>}
  </LabelStyle>
);

Label.propTypes = {
  children: PropTypes.node,
  informative: PropTypes.string,
};

Label.defaultProps = {
  children: "",
  informative: "",
};

export default Label;
