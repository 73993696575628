/* eslint-disable no-useless-escape */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import styled from "styled-components";
import css from "@styled-system/css";
import theme from "~tokens";

export const Container = styled("div")(
  css({
    display: "flex",
    flexFlow: "column",
    width: "100%",
    position: "relative",
  }),
  `

  @font-face {
  font-family: "revicons";
  fallback: fallback;
  src: url("./revicons.woff") format("woff"), url("./revicons.ttf") format("ttf"),
    url("./revicons.eot") format("ttf");
}
.react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.react-multi-carousel-track {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform, transition;
}
.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: rgba(0, 0, 0, 0.5);
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
}
.react-multiple-carousel__arrow:hover {
  background: rgba(0, 0, 0, 0.8);
}
.react-multiple-carousel__arrow::before {
  font-size: 20px;
  color: #fff;
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: 2;
  position: relative;
}
.react-multiple-carousel__arrow--left {
  left: calc(4% + 1px);
}
.react-multiple-carousel__arrow--left::before {
  content: "\e824";
}
.react-multiple-carousel__arrow--right {
  right: calc(4% + 1px);
}
.react-multiple-carousel__arrow--right::before {
  content: "\e825";
}
.react-multi-carousel-dot-list {
  position: absolute;
  bottom: 0;
  display: flex;
  left: 0;
  right: 0;
  justify-content: center;
  margin: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.react-multi-carousel-dot button {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  box-shadow: none;
  transition: background 0.5s;
  border-width: 2px;
  border-style: solid;
  border-color: grey;
  padding: 0;
  margin: 0;
  margin-right: 6px;
  outline: 0;
  cursor: pointer;
}
.react-multi-carousel-dot button:hover:active {
  background: #080808;
}
.react-multi-carousel-dot--active button {
  background: #080808;
}
.react-multi-carousel-item {
  transform-style: preserve-3d;
  backface-visibility: hidden;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .react-multi-carousel-item {
    flex-shrink: 0 !important;
  }
  .react-multi-carousel-track {
    overflow: visible !important;
  }
}


  .react-multi-carousel-dot-list {
    padding-bottom: 16px;
  }





  `,
);

export const CarouselContainer = styled("div")(
  css({
    position: "relative",
    width: "532px",
    paddingBottom: "10px",
  }),
  `${theme.media.xs} {
    width: 300px;
  }

  img {
    border-radius: 5px;
  }
  `,
);

export const Miniature = styled.div`
  transition: filter ease-in 200ms;
  filter: brightness(${(props) => (props.isSelected ? "1" : "0.4")});
  img {
    border-radius: 4px;
  }

  .card-wrapper {
    min-height: ${(props) => (props.optionFullWidth ? "150px" : "initial")};

    div {
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  & > * {
    width: ${(props) => (props.optionFullWidth ? "100%" : "121px")};
    display: block;
    min-height: 80px;
    cursor: pointer;
  }
`;

export const MiniatureContainer = styled("div")(
  css({
    width: "532px",
    minHeight: "80px",
    "&.cards-carousel": {
      ".react-multi-carousel-list > .react-multi-carousel-track li": {
        paddingBottom: "32px",
      },
    },
    ".react-multi-carousel-list > .react-multi-carousel-track li": {
      "> div": {
        height: "100%",
        "> img": {
          height: "100%",
          objectFit: "cover",
        },
      },
    },
  }),
  `${theme.media.xs} {
    width: 300px;
  }`,
);

export const MainSlide = styled.div`
  & > * {
    width: 100%;
    display: block;
    height: auto;
    margin: auto;
  }
`;
