/* eslint-disable implicit-arrow-linebreak */
import styled from "styled-components";
import css from "@styled-system/css";

export const ModalOverlay = styled("div")(
  css({
    position: " fixed",
    top: " 0",
    left: " 0",
    zIndex: " 1040",
    width: " 100vw",
    height: " 100vh",
    backgroundColor: "rgba(0,0,0,0.5)",
  }),
);

export const ModalWrapper = styled("div")(
  css({
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "1050",
    width: " 100%",
    height: " 100%",
    overflowX: "hidden",
    overflowY: "auto",
    outline: "0",
    p: 4,
  }),
);

export const ModalStyle = styled.div`
  ${({ width }) =>
    css({
      zIndex: "100",
      background: "white",
      position: "relative",
      borderRadius: "8px",
      maxWidth: width,
      padding: "16px",
      display: "grid",
      alignItems: "center",
    })}
`;

export const ModalContentStyle = styled("div")(
  css({
    alignSelf: "end",
  }),
);

export const ModalFooterStyle = styled("div")(
  css({
    display: "flex",
    flexDirection: "column",
    button: {
      marginLeft: "20px",
    },
  }),
);

export const Header = styled("div")(
  css({
    display: "grid",
    gridTemplateColumns: "1fr 0.1fr",
    alignItems: "center",
  }),
);

export const Close = styled("div")(
  css({
    margin: "0 0 0 auto",
    ":hover": {
      cursor: "pointer",
      opacity: "0.5",
    },
  }),
);
