import styled from "styled-components";
import css from "@styled-system/css";
import {
  compose, space, flexbox, layout
} from "styled-system";
import theme from "~tokens";

export const PanelStyle = styled("div")(
  css({
    width: "100%",
    display: "block",
    background: theme.colors.grayWhite,
    margin: "16px",
    padding: "8px",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
  }),
  compose(space, flexbox, layout),
);
