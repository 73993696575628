/* eslint-disable implicit-arrow-linebreak */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useSortBy,
  usePagination,
  useBlockLayout,
  useExpanded,
  useRowSelect,
} from "react-table";
import { useSticky } from "react-table-sticky";
import Footer from "~interface/footer";
import Cell from "../Cell";
import Scrollbar from "~core/scrollbar";
import { ReactComponent as Ascending } from "~assets/svgs/ascending.svg";
import { ReactComponent as Descending } from "~assets/svgs/descending.svg";
import { ReactComponent as Fixed } from "~assets/svgs/fixed.svg";

import {
  Table,
  Tr,
  Th,
  Td,
  Thead,
  Tbody,
  Icon,
  Container,
  FixedIcon,
  IconContainer,
} from "./TableDefaultStyle";

const TableComponent = ({
  columns,
  data,
  onUpdateTable,
  tableAttrs,
  sortBy,
  width,
  height,
  renderRowSubComponent,
  footer,
  footerAttrs,
  disableHeaderSort,
  customList,
  onHover,
  ...attrs
}) => {
  const selectedValue = customList.length && customList.find((select) => select.selected);
  const [inputText, setInputText] = useState({
    value: customList.length ? selectedValue.value : 25,
  });
  const [skipPageReset, setSkipPageReset] = useState(false);

  const defaultColumn = {
    Cell: (props) => <Cell {...props} />,
  };

  const updateData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    const newData = data.map((row, index) => {
      if (index === rowIndex) {
        return { ...data[rowIndex], [columnId]: value };
      }
      return row;
    });
    onUpdateTable(newData, rowIndex, columnId);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    visibleColumns,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, sortBy },
      autoResetPage: !skipPageReset,
      defaultColumn,
      updateData,
      disableSortRemove: true,
      disableMultiRemove: true,
    },
    useSortBy,
    useExpanded,
    usePagination,
    useBlockLayout,
    useSticky,
    useRowSelect,
  );

  useEffect(() => {
    setPageSize(inputText.value);
  }, [inputText.value, setPageSize]);

  const renderOrderIcon = (column) => {
    if (column.isSorted && column.isSortedDesc) {
      return <Descending />;
    }
    if (column.isSorted && !column.isSortedDesc) {
      return <Ascending />;
    }
    return null;
  };

  const renderHeaders = () =>
    headerGroups.map((headerGroup) => (
      <Tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column) => (
          <Th
            {...column.getHeaderProps([
              !disableHeaderSort && column.getSortByToggleProps(),
              { style: { width: column.width, justifyContent: column.align } },
            ])}
          >
            <IconContainer align={column.align}>
              <span>{column.render("Header")}</span>
              {renderOrderIcon(column) !== null && <Icon>{renderOrderIcon(column)}</Icon>}
              {column.sticky && (
                <FixedIcon>
                  <Fixed />
                </FixedIcon>
              )}
            </IconContainer>
          </Th>
        ))}
      </Tr>
    ));

  const renderBody = () =>
    page.map((row) => {
      prepareRow(row);
      return (
        <React.Fragment key={page.indexOf(row)}>
          <Tr {...row.getRowProps()}>
            {row.cells.map((cell) => (
              <Td
                {...cell.getCellProps([
                  {
                    style: {
                      width: cell.column.width,
                      justifyContent: cell.column.cellAlign || cell.column.align,
                    },
                  },
                ])}
              >
                {cell.render("Cell")}
              </Td>
            ))}
          </Tr>
          {row.isExpanded && (
            <tr>
              <td colSpan={visibleColumns.length}>{renderRowSubComponent({ row })}</td>
            </tr>
          )}
        </React.Fragment>
      );
    });

  return (
    <Container {...attrs} data-testid="table" id="table">
      <Scrollbar width={width} height={height} overflow>
        <Table {...getTableProps()} {...tableAttrs}>
          <Thead>{renderHeaders()}</Thead>
          <Tbody onHover={onHover} {...getTableBodyProps()}>
            {renderBody()}
          </Tbody>
        </Table>
      </Scrollbar>
      {footer && (
        <Footer
          onPageChange={(newPage) => gotoPage(newPage - 1)}
          totalPages={pageCount}
          currentPage={pageIndex + 1}
          totalItems={data.length}
          customList={customList}
          onChangePageSize={(option) => {
            setInputText(option);
            setPageSize(option.value);
          }}
          style={{ marginTop: "1em" }}
          {...footerAttrs}
        />
      )}
    </Container>
  );
};

TableComponent.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  onUpdateTable: PropTypes.func,
  tableAttrs: PropTypes.object,
  renderRowSubComponent: PropTypes.func,
  footerAttrs: PropTypes.object,
  sortBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool,
    }),
  ),
  width: PropTypes.string,
  height: PropTypes.string,
  footer: PropTypes.bool,
  disableHeaderSort: PropTypes.bool,
  customList: PropTypes.array,
  onHover: PropTypes.bool,
};

TableComponent.defaultProps = {
  onUpdateTable: () => {},
  columns: [],
  data: [],
  tableAttrs: {},
  footerAttrs: {},
  sortBy: [],
  width: "1136px",
  height: "560px",
  renderRowSubComponent: () => {},
  footer: true,
  disableHeaderSort: false,
  customList: [],
  onHover: false,
};

export default TableComponent;
