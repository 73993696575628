import styled from "styled-components";
import css from "@styled-system/css";
import {
  compose, space, flexbox, layout
} from "styled-system";
import theme from "~tokens";

export const LabelStyle = styled("label")(
  css({
    margin: "8px",
    background: theme.colors.accentPrimaryLight,
    borderRadius: "58px",
    padding: "2px 8px 2px 8px",
    font: theme.fonts.textRegularSmall,
    color: theme.colors.accentPrimaryDark,
    fontStyle: "normal",
    display: "inline-block",
    lineHeight: "1.6",
    textTransform: "uppercase",
    "&:first-of-type": {
      marginLeft: "0",
    },
    "&:last-of-type": {
      marginRight: "0",
    },
    "&.orange": {
      background: theme.colors.accentSecondaryLight,
      color: theme.colors.accentSecondaryDark,
    },
    "&.green": {
      background: theme.colors.green100,
      color: theme.colors.green400,
    },
    "&.yellow": {
      color: theme.colors.yellow400,
      background: theme.colors.yellow100,
    },
  }),
  compose(space, flexbox, layout),
);
