import styled from "styled-components";
import css from "@styled-system/css";
import theme from "~tokens";

export const CommentStyle = styled("div")(
  css({
    display: "flex",
    background: theme.colors.grayWhite,
    padding: "16px",
    maxWidth: "392px",
    "&:hover": {
      background: theme.colors.blue100,
    },
  }),
);

export const AvatarContainer = styled("div")(
  css({
    position: "relative",
    height: "100%",
    paddingRight: "16px",
  }),
);

export const CommentContainer = styled("div")(
  css({
    "& > h1, h2, h3": {
      fontSize: "14px",
      fontWeight: "700",
      lineHeight: "20px",
      color: theme.colors.gray500,
      marginBottom: "8px",
      marginTop: "2px",
    },
    "& > p": {
      color: theme.colors.gray500,
      fontSize: "12px",
      lineHeight: "16px",
      maxWidth: "100%",
    },
  }),
);
