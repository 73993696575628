import React, { Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { ButtonStyle, Close } from "./ChipStyle";
import { CloseIcon } from "~svgs";

const Chip = ({ children, onClose, hasCloseButton, active, isVisible, className, ...attrs }) => (
  <Fragment>
    {isVisible && (
      <ButtonStyle className={clsx(active && "active", className)} data-testid="chip" {...attrs}>
        {children}
        {hasCloseButton && (
          <Close onClick={onClose} className="close">
            <CloseIcon />
          </Close>
        )}
      </ButtonStyle>
    )}
  </Fragment>
);

Chip.propTypes = {
  children: PropTypes.string,
  className: PropTypes.any,
  onClose: PropTypes.func,
  hasCloseButton: PropTypes.bool,
  active: PropTypes.bool,
  isVisible: PropTypes.bool,
};

Chip.defaultProps = {
  children: "",
  className: "",
  onClose: () => {},
  hasCloseButton: false,
  active: false,
  isVisible: true,
};

export default Chip;
