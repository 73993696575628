import React from "react";
import PropTypes from "prop-types";
import { DialogFooterStyle } from "./DialogStyle";
import Flex from "~core/flex";

const DialogFooter = ({ children, ...attrs }) => (
  <DialogFooterStyle {...attrs}>
    <Flex style={{ marginTop: "16px" }} justifyContent="flex-end">{children}</Flex>
  </DialogFooterStyle>
);

DialogFooter.propTypes = {
  children: PropTypes.node,
};

DialogFooter.defaultProps = {
  children: "",
};
export default DialogFooter;
