/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, Fragment } from "react";
import PropTypes from "prop-types";
import {
  InputGroup,
  Label,
  Field,
  FileGroup,
  InputRange,
  DecrementButton,
  IncrementButton,
  FieldIcon,
  ColorText,
  StatusText,
  Prefix,
  SpanFix,
  FieldContainer,
} from "./InputStyle";
import Select from "~form/select";
import { SelectGroupDropdown } from "~form/select/SelectStyle";

import {
  DecrementIcon,
  IncrementIcon,
  FolderIcon,
  AlarmIcon,
  ColorIcon,
  SuccessIcon,
  ErrorIcon,
  InfoIcon,
  WarningIcon,
  HelpIcon,
} from "~svgs";

const Input = ({
  name,
  label,
  type,
  value,
  placeholder,
  required,
  width,
  height,
  disabled,
  prefix,
  suffix,
  min,
  max,
  step,
  rows,
  incrementButtons,
  topDropdown,
  options,
  status,
  autocomplete,
  statusMessage,
  onChange,
  onBlur,
  prefixClassName,
  inputGroupStyle,
  numTrunc,
  noBorder,
  ref,
  selectValue,
  ...attrs
}) => {
  const [valueField, setValueField] = useState(type === "color" ? "#000000" : "");
  const [focus, setFocus] = useState(false);
  const [open, setOpen] = useState(false);
  const [fileTitle, setFileTitle] = useState();
  const [valueChangeInput, setValueChangeInput] = useState("");
  const [optionsInput] = useState(options);
  const [incrementButtonPosition, setIncrementButtonPosition] = useState(0);
  const [msgValidationDate, setMsgValidationDate] = useState();
  let inputRef = useRef(null);
  const inputGroupRef = useRef(null);
  const incrementRef = useRef(null);
  const decrementRef = useRef(null);
  const prefixValue = prefix;
  const suffixValue = suffix || (type === "hour" && "h");
  const [autoCompleteType, setAutoCompleteType] = useState("off");

  useEffect(() => {
    const array = ["text", "select", "textarea"];
    if (autocomplete && array.includes(type)) {
      setAutoCompleteType(name || "on");
    } else {
      setAutoCompleteType("off");
    }
  }, [autocomplete, name, type]);

  useEffect(() => {
    if (ref) {
      inputRef = ref;
    }
  }, [ref]);

  useEffect(() => {
    if (!inputRef.current) return;
    const inputNode = inputRef.current;
    const inputGroupNode = inputGroupRef.current;

    if (inputNode && inputGroupNode) {
      inputGroupNode.addEventListener("click", () => {
        if (!focus) {
          inputNode.focus();
          setFocus(true);
        }
      });

      inputNode.addEventListener("focus", () => setFocus(true));
      inputNode.addEventListener("focusout", () => {
        setFocus(false);
      });
    }
  }, [inputRef, valueField]);

  useEffect(() => {
    setValueField(value);
  }, [value]);

  useEffect(() => {
    if (type === "number") {
      const { length } = `${valueField}`;
      const letterWidth = 4.5 + length;

      setIncrementButtonPosition(letterWidth);
    }
  }, [name, type, value, valueField]);

  const prefixOrSuffixValues = (valuesInput) => {
    let typedValue = "";
    if (prefixValue) {
      typedValue = valuesInput
        .replace("?", "")
        .replace(new RegExp(prefixValue, "g"), "")
        .replace("R$", "")
        .replace(" ", "");
      setValueField(typedValue);
    } else if (suffixValue) {
      typedValue = valuesInput
        .replace(" ", "")
        .replace(" ", "")
        .replace("R$", "")
        .replace(new RegExp(suffixValue, "g"), "");
      setValueField(`${typedValue} ${suffixValue} `);
    } else {
      typedValue = valuesInput;
      setValueField(`${typedValue}`);
    }
  };

  const handleTimeChange = (event) => {
    const keyPress = Number(event.key);
    const charCode = event.charCode ? event.charCode : event.keyCode;

    if (charCode !== 8 && charCode !== 9) {
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(keyPress)) {
        let hour = "";
        hour += event.target.value;

        if (hour.length === 3) {
          hour += ":";
          document.getElementById(`vli-${name.replace(" ", "-").toLowerCase()}`).value = hour;
        }
        let typedValue = "";
        typedValue = hour
          .replace(" ", "")
          .replace(" ", "")
          .replace("R$", "")
          .replace(new RegExp(suffixValue, "g"), "");
        document.getElementById(
          `vli-${name.replace(" ", "-").toLowerCase()}`,
        ).value = `${typedValue}${suffixValue}`;

        document
          .getElementById(`vli-${name.replace(" ", "-").toLowerCase()}`)
          .addEventListener("input", (e) => {
            if (e.inputType === "deleteContentBackward") {
              document.getElementById(`vli-${name.replace(" ", "-").toLowerCase()}`).value = "";
            }
          });

        if (hour.length === 6) {
          validateTime(hour);
        }
      }
    }
  };

  const validateTime = (time) => {
    const hour = time.substring(0, 2);
    const minutes = time.substring(3, 6).replace("h", "");
    let statusValidations = false;
    if (
      String(hour) < "00"
      || hour < 0
      || hour > 23
      || String(minutes) < "00"
      || minutes > 59
      || minutes < 0
    ) {
      statusValidations = true;
    }
    if (statusValidations) {
      setMsgValidationDate(statusMessage ? `Hora inválida e ${statusMessage}` : "Hora inválida");
      inputRef.current.focus();
    } else {
      setMsgValidationDate("");
      setValueField(`${hour}:${minutes}`);
    }
  };

  const renderSearchResults = () => {
    const valueChangeInputLc = valueChangeInput.toLocaleLowerCase();
    const filter = optionsInput.filter((option) => {
      const optionLc = option.label.toLowerCase();
      return optionLc.match(valueChangeInputLc);
    });
    return (
      <SelectGroupDropdown active={open}>
        <ul key={Math.random()}>
          {filter.length > 0 ? (
            filter.map((option, index) => (
              <li key={`${index}_${Math.random()}`}>
                <button
                  type="button"
                  onClick={() => {
                    setValueField(option.label);
                    setOpen(!open);
                  }}
                  title="Selecionar opção"
                  aria-label="Selecionar opção"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: option.label.replace(
                        valueChangeInput,
                        `<strong>${valueChangeInput}</strong>`,
                      ),
                    }}
                  />
                </button>
              </li>
            ))
          ) : (
            <li>
              <button
                type="button"
                onClick={() => {
                  setValueField("");
                  setOpen(!open);
                }}
                title="Selecionar opção"
                aria-label="Selecionar opção"
              >
                Nenhum {label} encontrado!
              </button>
            </li>
          )}
        </ul>
      </SelectGroupDropdown>
    );
  };

  const renderStatus = () => {
    const icon = {
      success: <SuccessIcon />,
      error: <ErrorIcon />,
      info: <InfoIcon />,
      warning: <WarningIcon />,
      help: <HelpIcon />,
    };

    return status && <FieldIcon>{icon[status]}</FieldIcon>;
  };

  const renderIconInput = () => {
    let icon = "";
    switch (type) {
      case "hour":
        icon = <AlarmIcon />;
        break;
      case "color":
        icon = <ColorIcon />;
        break;
      default:
        return null;
    }

    return <FieldIcon type={type}>{icon}</FieldIcon>;
  };

  const baseInput = () => {
    switch (type) {
      case "select":
        return (
          <Select
            {...attrs}
            autocomplete={autocomplete}
            placeholder={placeholder}
            topDropdown={topDropdown}
            disabled={disabled}
            options={options}
            selectValue={selectValue}
            onChange={(e) => {
              setValueField(e.value);
              setValueChangeInput(e.value);
              onChange(e.value);
            }}
            onBlur={onBlur}
            numTrunc={numTrunc}
            noBorder={noBorder}
            selected={valueField}
            focus={focus}
          />
        );
      case "hour":
        return (
          <FieldContainer
            prefix={prefix}
            suffix={suffix}
            noBorder={noBorder}
            focus={focus}
            style={{ position: "relative", ...inputGroupStyle }}
          >
            <Field
              as="input"
              type="text"
              maxLength="6"
              name={name}
              height={height}
              placeholder={placeholder}
              disabled={disabled}
              autoComplete={autoCompleteType}
              ref={inputRef}
              focus={focus}
              onKeyUp={(e) => {
                handleTimeChange(e);
                setValueField(e.target.value);
                setValueChangeInput(e.target.value);
                onChange(e.target.value);
              }}
              id={`vli-${name.replace(" ", "-").toLowerCase()}`}
              onBlur={onBlur}
              onChange={(e) => {
                setValueField(e.target.value);
                setValueChangeInput(e.target.value);
                onChange(e.target.value);
              }}
              value={valueField}
              {...attrs}
            />
            {renderIconInput()}
          </FieldContainer>
        );
      case "color":
        return (
          <FieldContainer
            noBorder={noBorder}
            focus={focus}
            style={{ position: "relative", ...inputGroupStyle }}
          >
            <Field
              as="input"
              type="color"
              name={name}
              height={height}
              disabled={disabled}
              ref={inputRef}
              focus={focus}
              onBlur={onBlur}
              onChange={(e) => {
                setValueField(e.target.value);
                setValueChangeInput(e.target.value);
                onChange(e.target.value);
              }}
              {...attrs}
            />
            <ColorText>{valueField || "#000000"}</ColorText>
            {renderIconInput()}
          </FieldContainer>
        );
      case "range":
        return (
          <InputRange
            as="input"
            type="range"
            name={name}
            height={height}
            disabled={disabled}
            ref={inputRef}
            focus={focus}
            value={valueField}
            id={`vli-${name.replace(" ", "-").toLowerCase()}`}
            onChange={(e) => {
              setValueField(e.target.value);
              setValueField(e.target.value);
              prefixOrSuffixValues(e.target.value);
              onChange(e.target.value);
            }}
            onBlur={onBlur}
            incrementButtons={incrementButtons}
            min={min}
            max={max}
            {...attrs}
          />
        );
      case "file":
        return (
          <FieldContainer
            prefix={prefix}
            disabled={disabled}
            height={height}
            suffix={suffix}
            noBorder={noBorder}
            focus={focus}
            style={{
              position: "relative",
              zIndex: "unset",
              ...inputGroupStyle,
            }}
          >
            {prefix && (
              <Prefix id="prefix-label" className={prefixClassName}>
                {prefix}
              </Prefix>
            )}
            <Field
              data-testid="testeComponentInput"
              as="input"
              type={type}
              name={name}
              height={height}
              placeholder={placeholder}
              disabled={disabled}
              ref={inputRef}
              focus={focus}
              statusInput={!!status}
              prefix={prefix}
              rows={rows}
              id={`vli-${name.replace(" ", "-").toLowerCase()}`}
              onChange={(e) => {
                if (e.target.files.length > 0) {
                  setFileTitle(e.target.files[0].name);
                } else {
                  setFileTitle("");
                }
                onChange(e.target.files[0]);
              }}
              onBlur={onBlur}
              {...attrs}
            />
            {suffix && (
              <SpanFix id="prefix-label" className={prefixClassName}>
                {suffix}
              </SpanFix>
            )}
          </FieldContainer>
        );
      default:
        return (
          <FieldContainer
            prefix={prefix}
            disabled={disabled}
            height={height}
            suffix={suffix}
            noBorder={noBorder}
            focus={focus}
            style={{
              position: "relative",
              height: type === "textarea" && "auto",
              ...inputGroupStyle,
            }}
          >
            {prefix && (
              <Prefix id="prefix-label" className={prefixClassName}>
                {prefix}
              </Prefix>
            )}
            <Field
              data-testid="testeComponentInput"
              as={type === "textarea" ? "textarea" : "input"}
              type={type === "search" ? "text" : type}
              name={name}
              height={height}
              placeholder={placeholder}
              disabled={disabled}
              autoComplete={autoCompleteType}
              ref={inputRef}
              focus={focus}
              statusInput={!!status}
              prefix={prefix}
              value={valueField || ""}
              rows={rows}
              id={`vli-${name.replace(" ", "-").toLowerCase()}`}
              onChange={(e) => {
                setValueField(e.target.value);
                setValueChangeInput(e.target.value);
                onChange(e.target.value);
                setOpen(true);
              }}
              onBlur={onBlur}
              incrementButtons={incrementButtons}
              min={type === "number" ? min || 0 : ""}
              max={type === "number" ? max || 100 : ""}
              step={type === "number" ? step || 1 : ""}
              {...attrs}
            />
            {suffix && (
              <SpanFix id="prefix-label" className={prefixClassName}>
                {suffix}
              </SpanFix>
            )}
            {status && renderStatus()}
            {type === "search" && valueChangeInput !== "" && renderSearchResults()}
          </FieldContainer>
        );
    }
  };

  const renderInput = () => {
    switch (type) {
      case "number":
        return (
          <>
            {incrementButtons && (
              <DecrementButton
                ref={decrementRef}
                type="button"
                title="Diminuir valor"
                aria-label="Diminuir valor"
                focus={focus}
                onClick={
                  valueField >= min
                    ? () => setValueField(Number(valueField) - step)
                    : setValueField(min)
                }
              >
                <DecrementIcon />
              </DecrementButton>
            )}

            {baseInput()}

            {incrementButtons && (
              <IncrementButton
                ref={incrementRef}
                type="button"
                title="Aumentar valor"
                aria-label="Aumentar valor"
                style={{ left: `calc(${incrementButtonPosition}ch + 4px)` }}
                focus={focus}
                onClick={
                  valueField <= max
                    ? () => setValueField(Number(valueField) + step)
                    : setValueField(max)
                }
              >
                <IncrementIcon />
              </IncrementButton>
            )}
          </>
        );
      case "file":
        return (
          <>
            {baseInput()}
            <FileGroup
              statusInput={!!status}
              as="div"
              disabled={disabled}
              fileTitle={fileTitle}
              style={{ top: label ? "20px" : "-1px" }}
            >
              <label htmlFor={`vli-${name.replace(" ", "-").toLowerCase()}`}>
                <span>{fileTitle || placeholder}</span>
                <FolderIcon />
              </label>
            </FileGroup>
          </>
        );
      case "color":
        return <Fragment>{baseInput()}</Fragment>;
      default:
        return baseInput();
    }
  };

  return (
    <InputGroup width={width} ref={inputGroupRef}>
      {label && (
        <Label disabled={disabled}>
          {label}
          {!required && <span> (Opcional)</span>}
        </Label>
      )}

      {renderInput()}

      {msgValidationDate ? (
        <StatusText variant={status}>{msgValidationDate}</StatusText>
      ) : (
        status && <StatusText variant={status}>{statusMessage}</StatusText>
      )}
    </InputGroup>
  );
};

Input.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  disabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  rows: PropTypes.number,
  incrementButtons: PropTypes.bool,
  topDropdown: PropTypes.bool,
  autocomplete: PropTypes.bool,
  options: PropTypes.array,
  status: PropTypes.string,
  statusMessage: PropTypes.string,
  suffix: PropTypes.string,
  prefix: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  prefixClassName: PropTypes.any,
  inputGroupStyle: PropTypes.object,
  numTrunc: PropTypes.number,
  noBorder: PropTypes.bool,
  ref: PropTypes.object,
  selectValue: PropTypes.string,
};

Input.defaultProps = {
  name: "",
  label: "",
  type: "",
  value: "",
  placeholder: "",
  required: false,
  width: "auto",
  height: "40px",
  disabled: false,
  min: 0,
  max: 100,
  step: 1,
  rows: 1,
  incrementButtons: false,
  topDropdown: false,
  autocomplete: false,
  options: [],
  status: "",
  statusMessage: "",
  suffix: "",
  prefix: "",
  onChange: () => {},
  onBlur: () => {},
  prefixClassName: "",
  inputGroupStyle: {},
  numTrunc: 70,
  noBorder: false,
  ref: null,
  selectValue: "",
};

export default Input;
