import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CloseIcon, ErrorIcon, WarningIcon, SuccessIcon } from "~svgs";
import Heading from "~typography/heading";
import DialogContent from "~core/dialog/DialogContent";
import DialogFooter from "~core/dialog/DialogFooter";
import { DialogOverlay, DialogWrapper, DialogStyle, Header, Close } from "./DialogStyle";

const Dialog = ({
  isOpen,
  title,
  onClose,
  children,
  dialogBox,
  warning,
  success,
  error,
  actions,
  ...attrs
}) => {
  const [isShowing, setIsShowing] = useState(isOpen);

  useEffect(() => {
    setIsShowing(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (isShowing && !isOpen) {
      onClose();
    }
  }, [isOpen, isShowing, onClose]);

  const closeDialog = () => {
    onClose();
    setIsShowing(false);
  };

  return (
    isShowing && (
      <DialogOverlay data-testid="dialog-test">
        <DialogWrapper>
          <DialogStyle {...attrs}>
            <Header className="dialog-box">
              {dialogBox && error && <ErrorIcon />}
              {dialogBox && warning && <WarningIcon />}
              {dialogBox && success && <SuccessIcon />}
              <Heading tag="h6" variant={[{ bold: true }]}>
                {title}
              </Heading>
              <Close onClick={closeDialog}>
                <CloseIcon />
              </Close>
            </Header>
            <DialogContent>{children}</DialogContent>
            {actions && <DialogFooter>{actions}</DialogFooter>}
          </DialogStyle>
        </DialogWrapper>
      </DialogOverlay>
    )
  );
};

Dialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  dialogBox: PropTypes.bool,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  success: PropTypes.bool,
  actions: PropTypes.node,
};

Dialog.defaultProps = {
  isOpen: false,
  title: "",
  children: "",
  onClose: () => {},
  dialogBox: false,
  error: false,
  warning: false,
  success: false,
  actions: "",
};

export default Dialog;
