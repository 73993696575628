import React from "react";
import PropTypes from "prop-types";
import { BreadStyle } from "./BreadcrumbStyle";

const Bread = ({ children, ...attrs }) => <BreadStyle {...attrs}>{children}</BreadStyle>;

Bread.propTypes = {
  children: PropTypes.node,
};

Bread.defaultProps = {
  children: "",
};

export default Bread;
