import styled from "styled-components";
import css from "@styled-system/css";
import {
  compose, space, flexbox, layout
} from "styled-system";
import theme from "~tokens";

export const ButtonStyle = styled("button")(
  css({
    border: "none",
    outline: "none",
    padding: "8px",
    background: theme.colors.gray100,
    cursor: "pointer",
    color: theme.colors.accentPrimaryDark,
    font: theme.fonts.textRegularSmall,
    fontStyle: "normal",
    borderRadius: "47px",
    display: "flex",
    alignItems: "center",
    "& > div:first-of-type": {
      marginRight: "10px",
      background: "rgba(215, 229, 244, 0.4)",
      "& > div.status": {
        display: "none",
      },
      "&.close": {
        marginRight: "0px",
      },
    },
    "& > div.close": {
      marginLeft: "8px",
      position: "relative",
      background: "transparent",
      top: "2px",
      "& > div.status": {
        display: "none",
      },
      "& > svg > path, & > svg g path": {
        fill: theme.colors.accentPrimaryDark,
      },
    },
    "&.active": {
      background: "rgba(0,0,0, 0.1)",
      boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.12)",
    },
  }),
  compose(space, flexbox, layout),
);

export const Close = styled("div")(
  css({
    margin: "0 0 0 auto",
    ":hover": {
      cursor: "pointer",
      opacity: "0.5",
    },
  }),
);
