import styled from "styled-components";
import css from "@styled-system/css";
import {
  compose, variant, color, space, typography, layout
} from "styled-system";
import theme from "~tokens";

export const TextTag = styled("")(
  ({ size }) => {
    switch (size) {
      case "medium":
        return css({ font: theme.fonts.textRegularMedium });
      case "large":
        return css({ font: theme.fonts.textRegularLarge });
      default:
        return css({ font: theme.fonts.textRegularSmall });
    }
  },
  color({ color: color || theme.colors.gray500 }),
  variant({
    variants: {
      bold: {
        fontWeight: "bold",
      },
    },
  }),
  compose(typography, color, space, layout),
);
