import styled from "styled-components";
import css from "@styled-system/css";
import { compose, space, flexbox, layout } from "styled-system";
import theme from "~tokens";

export const ErrorContainerStyle = styled("div")(
  css({
    display: "grid",
    width: "100%",
    maxWidth: "452px",
    margin: "0 auto",
    alignItems: "center",
    "& > svg": {
      margin: "0 auto",
      marginBottom: "30px",
    },
    "& > div.content": {
      width: "95%",
      margin: "0 auto",
      marginTop: "28px",
      display: "grid",
      textAlign: "center",
      color: theme.colors.gray400,
      "& > p": {
        fontSize: "16px",
      },
      "& > button": {
        margin: "0 auto",
        marginTop: "36px",
        "& > svg": {
          marginRight: "8px",
          "& > path": {
            fill: "#000000",
          },
        },
      },
    },
  }),
  compose(space, flexbox, layout),
);

export const ErrorCodeStyle = styled("div")(
  css({
    display: "block",
    textAlign: "center",
    "& > span": {
      fontWeight: "500",
    },
  }),
);

export const ErrorSvgStyle = styled("div")(
  css({
    display: "block",
    width: "100%",
    margin: "0 auto",
    marginBottom: theme.spacing.xl,
  }),
);

export const CustomContainer = styled("div")(
  css({
    marginBottom: theme.spacing.xl,
    display: "flex",
    justifyContent: "center",
  }),
);
