import styled from "styled-components";
import css from "@styled-system/css";
import { compose, space, flexbox, layout } from "styled-system";
import theme from "~tokens";

const { md, xs, sm } = theme.media;

export const Span = styled("div")(
  css({
    position: "absolute",
    right: "8px",
    top: "27px",
    cursor: "pointer",
    display: "none",
    [xs]: {
      display: "block",
    },
  }),
);

export const MenuStyle = styled("nav")(
  ({ navPosition }) => css({
    position: navPosition,
  }),
  css({
    transition: "0.2s",
    bg: theme.colors.grayWhite,
    maxWidth: "224px",
    width: "224px",
    height: "100vh",
    padding: "8px",
    zIndex: 1,
    overflowX: "visible",
    left: "0",
    top: "0",
    "&::-webkit-scrollbar": {
      width: "2px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.colors.accentPrimaryDark,
      borderRadius: "4px",
    },
    [md]: {
      maxWidth: "224px",
    },
    "&.expanded": {
      maxWidth: "224px",
      "& > div.profile > nav > div > span": {
        display: "inline-block",
      },
      "& > button.hide-menu": {
        paddingLeft: "7px",
        "& > span": {
          display: "inline-block",
        },
        "& > svg": {
          margin: "0",
        },
      },
      "& > ul > li": {
        display: "flex",
        alignItems: "center",
        "& > button > span": {
          color: theme.colors.gray400,
          position: "relative",
          textAlign: "left",
          paddingLeft: "6px",
        },
        "& > span": {
          color: theme.colors.gray400,
          position: "relative",
          textAlign: "left",
          paddingLeft: "6px",
        },
      },
    },
    "& > div.header > h2": {
      font: theme.fonts.textBoldLarge,
      color: theme.colors.gray500,
      display: "none",
      position: "relative",
      margin: "20px",
      marginLeft: "0px",
      [xs]: {
        display: "block",
      },
    },
    "& > span": {
      position: "absolute",
      right: "5px",
      top: "21px",
      cursor: "pointer",
      display: "none",
      [xs]: {
        display: "block",
      },
    },
    [xs]: {
      position: "fixed",
      right: "-224px",
      top: "0px",
      left: "inherit",
      maxWidth: "224px",
      height: "100%",
      transition: "0.5s",
      "& > div.header": {
        "& > button": {
          display: "none",
        },
        "& > svg": {
          display: "none",
        },
        "& > span": {
          display: "none",
        },
      },
    },
    "& > button > div.tooltip": {
      display: "none",
      [md]: {
        display: "block",
      },
      [xs]: {
        display: "none",
      },
    },
    "&.collapse": {
      maxWidth: "80px",
      "& > .profile .username": {
        visibility: "hidden",
      },
      "& > div > span": {
        visibility: "hidden",
      },
      "& > div > nav > div": {
        "& > span": {
          display: "none",
        },
        "& > b": {
          right: "6px",
        },
      },
      "& > ul > li": {
        justifyContent: "center",
      },
      "& > ul > li > div > span > p, ul > li > div > span > button > p": {
        visibility: "hidden",
        position: "absolute",
      },
      "& > ul > li > div > span > svg": {
        transition: "0.5s",
        margin: "0 auto",
      },
      "& > ul > li > div > span > button > svg": {
        transition: "0.5s",
        margin: "0 auto",
      },
      transition: "0.5s",
      "& > ul > li > div > .tooltip, ul > li > div > .tooltip": {
        display: "block",
        width: "auto",
        [md]: {
          display: "block",
        },
        [xs]: {
          display: "none",
        },
      },
      "& > div > div.tooltip": {
        display: "block",
        width: "100%",
        [md]: {
          display: "block",
        },
        [xs]: {
          display: "none",
        },
      },
    },
    "& > div.profile ": {
      width: "100%",
      [md]: {
        "& > nav > div > p": {
          display: "none",
        },
        width: "100%",
        "& > nav": {
          "& > div > b": {
            right: "4px",
          },
        },
      },
      [sm]: {
        "& > nav > div > p": {
          display: "hidden",
        },
        "& > nav > ul": {
          position: "static",
          zIndex: 0,
          paddingLeft: "0px",
          width: "100%",
          boxShadow: "none",
          "& > li": {
            "&:first-of-type": {
              "& > div": {
                "&:first-of-type": {
                  display: "block",
                },
              },
            },
            "> span": {
              visibility: "visible",
            },
          },
        },
      },
      [xs]: {
        "& > nav > div > p": {
          display: "inline-block",
          visibility: "visible",
        },
        "& > nav > div > div": {
          margin: "0",
          marginRight: "8px",
        },
        "& > nav > ul": {
          position: "static",
          width: "100%",
          marginTop: "8px",
        },
      },
    },
  }),
  compose(space, flexbox, layout),
);
