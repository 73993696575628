import styled from "styled-components";
import css from "@styled-system/css";
import { compose, space, layout } from "styled-system";
import theme from "~tokens";

import Flex from "~core/flex";

export const Container = styled("div")(
  css({
    position: "relative",
    userSelect: "none",
    ".drop-options--trigger": {
      cursor: "pointer",
      position: "absolute",
      right: "8px",
      top: "11px",
    },
  }),
  compose(space, layout),
);

export const Tags = styled(Flex)(
  css({
    position: "absolute",
    top: "8px",
    zIndex: 1,
    ".clear-button": {
      cursor: "pointer",
      transition: "transform .2s ease, opacity 0.1s linear",
      "&:hover": {
        transform: "scale(0.9)",
        opacity: "0.5",
      },
    },
  }),
  ({ alignRight }) => (alignRight ? css({ right: "32px" }) : css({ left: "8px" })),
);

export const Tag = styled("div")(
  css({
    display: "flex",
    position: "relative",
    bg: theme.colors.accentPrimaryLight,
    font: theme.fonts.textRegularSmall,
    color: theme.colors.accentPrimaryDark,
    borderRadius: "2px",
    padding: 1,
    svg: {
      ml: 1,
      "path:nth-child(1)": { fill: "transparent" },
      "path:nth-child(2)": { stroke: theme.colors.accentPrimaryDark },
    },
  }),
  compose(space, layout),
);

export const SearchInput = styled("input")(
  css({
    position: "relative",
    padding: 2,
    width: "100%",
    height: "40px",
    boxSizing: "border-box",
    border: "1px solid",
    borderColor: theme.colors.gray200,
    borderRadius: "4px",
    "&::-webkit-search-decoration, &::-webkit-search-cancel-button, &::-webkit-search-results-button, &::-webkit-search-results-decoration": {
      display: "none",
    },
    "&:focus": {
      borderColor: theme.colors.accentPrimaryDark,
      boxShadow: `0 0 0 2px ${theme.colors.accentPrimaryLight}`,
    },
  }),
  ({ openOptions }) => openOptions && css({ borderColor: theme.colors.accentPrimaryDark }),
);

export const OptionsList = styled("ul")(
  css({
    zIndex: 10,
    padding: 0,
    position: "absolute",
    userSelect: "none",
    top: "40px",
    left: "0",
    width: "100%",
    bg: theme.colors.grayWhite,
    marginTop: 1,
    borderRadius: "4px",
    boxShadow: "0px -1px 2px rgba(0, 0, 0, 0.08), 0px 2px 3px rgba(0, 0, 0, 0.12)",
    maxHeight: "264px",
    overflowY: "scroll",
    "::-webkit-scrollbar": {
      width: "4px",
      borderRadius: "4px",
    },
    "::-webkit-scrollbar-track": {
      background: "transparent",
      "&:hover": {
        width: "10px",
        cursor: "pointer",
        bg: theme.colors.accentPrimaryLight,
      },
    },
    "::-webkit-scrollbar-thumb": {
      bg: theme.colors.accentPrimaryDark,
    },
  }),
);

export const Group = styled("ul")(
  css({
    position: "relative",
    userSelect: "none",
    listStyle: "none inside none",
    margin: "0 0 16px 0",
    padding: 0,
    "&[title]::before": {
      content: "attr(title)",
      display: "flex",
      userSelect: "none",
      alignItems: "center",
      font: theme.fonts.textBoldSmall,
      textTransform: "uppercase",
      color: theme.colors.gray300,
      height: "32px",
      paddingLeft: 2,
    },
  }),
);

export const GroupItem = styled("li")(
  css({
    position: "relative",
    userSelect: "none",
    display: "flex",
    alignItems: "center",
    padding: "11px",
    cursor: "pointer",
    "&:hover": {
      bg: theme.colors.accentPrimaryLight,
    },
    "&[aria-selected='true'] > label > label > span": {
      color: theme.colors.accentPrimaryDark,
    },
  }),
  ({ focused }) => focused && css({ bg: theme.colors.accentPrimaryLight }),
);
