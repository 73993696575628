import styled from "styled-components";
import css from "@styled-system/css";
import { compose, space, flexbox, layout } from "styled-system";
import theme from "~tokens";

const { md, xs } = theme.media;

export const BodyNav = styled("div")(
  css({
    ".username": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "18ch",
      userSelect: "none",
      color: theme.colors.gray400,
      [md]: {
        visibility: "visible",
      },
      [xs]: {
        visibility: "hidden",
      },
    },
    ".toggle": {
      position: "absolute",
      right: "-4px",
      transition: "0.3s",
      transform: "rotate(0deg)",
      "&.hide": {
        transition: "0.3s",
        transform: "rotate(180deg)",
      },
    },
  }),
);

export const ContainerProfile = styled("div")(
  css({
    bg: "#F5F5F5",
    width: "222px",
    display: "flex",
    borderRadius: "4px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: theme.colors.gray400,
    [xs]: {
      width: "64px",
    },
    "& > nav": {
      display: "block",
      width: "100%",
      flexDirection: "row",
      padding: "8px",
      position: "relative",
      "& > div": {
        cursor: "pointer",
        "& > img": {
          width: "100%",
          borderRadius: "100%",
          objectFit: "cover",
        },
        "& > strong": {
          textAlign: "center",
          width: "34px",
          background: theme.colors.accentPrimaryLight,
          height: "34px",
          display: "block",
          paddingTop: "12px",
          borderRadius: "100%",
          color: theme.colors.accentPrimaryDark,
          font: theme.fonts.textRegularSmall,
        },
      },
      "& > ul": {
        font: theme.fonts.textRegularSmall,
        listStyle: "none",
        width: "100%",
        display: "block",
        [md]: {
          position: "static",
          zIndex: 0,
          paddingLeft: "0px",
          width: "100%",
          boxShadow: "none",
        },
        [xs]: {
          position: "absolute",
          zIndex: 1,
          paddingTop: "0px",
          background: "#F5F5F5",
          paddingBottom: "8px",
          paddingLeft: "8px",
          width: "220px",
          borderRadius: "4px",
          top: "57px",
          boxShadow: "-1px 1px 5px 0px #CCCCCC",
          left: "2px",
        },
        "&.hide": {
          display: "none",
        },
      },
    },
  }),
  compose(space, flexbox, layout),
);
