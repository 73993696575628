import styled from "styled-components";
import css from "@styled-system/css";
import theme from "~tokens";

export const EmptyStateContainer = styled("div")(
  css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  }),
);

export const EmptyStateBox = styled("div")(
  css({
    maxWidth: "362px",
  }),
  ({ condensed }) => condensed && css({ padding: "32px" }),
);

export const IconStyle = styled("div")(
  css({
    display: "flex",
    justifyContent: "center",
  }),
);

export const ContentStyle = styled("div")(
  css({
    textAlign: "center",
    "& > p": {
      color: theme.colors.gray400,
      font: theme.fonts.textRegularMedium,
    },
  }),
  ({ condensed }) => condensed
    && css({
      display: "flex",
      justifyContent: "center",
      marginTop: "6px",
      "& > h2": {
        marginTop: "6px",
        maxWidth: "128px",
        font: theme.fonts.textRegularMedium,
        color: theme.colors.gray300,
      },
    }),
  ({ condensed }) => !condensed
    && css({
      marginTop: "24px",
      "& > h2": {
        font: theme.fonts.headingBoldH2,
        color: theme.colors.gray500,
        marginBottom: "16px",
      },
    }),
);
