import React from "react";
import PropTypes from "prop-types";
import { MenuItemContainerStyle, MenuItemStyle } from "./ItensProfileStyle";
import Divider from "~core/divider/Divider";

const ItensProfile = ({
  onClick, children, active, danger, ...attrs
}) => (
  <MenuItemContainerStyle className={danger && "menu-danger"} {...attrs}>
    <Divider style={{ margin: "8px 0px 12px 0px" }} position="right" />
    <MenuItemStyle className={danger && "menu-danger--content"} onClick={() => onClick()}>{children}</MenuItemStyle>
  </MenuItemContainerStyle>
);

ItensProfile.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  danger: PropTypes.bool,
  onClick: PropTypes.func,
};

ItensProfile.defaultProps = {
  children: "",
  active: false,
  danger: false,
  onClick: () => {},
};

export default ItensProfile;
