import React from "react";
import PropTypes from "prop-types";
import Icon from "~core/icon";
import {
  CircleIcon,
  CircleEmptyIcon,
  SquareIcon,
  SquareEmptyIcon,
  StarIcon,
  CheckIcon,
} from "~svgs";
import { ListStyle, ListItem, Counter } from "./ListStyle";

const List = ({ type, marker, listItems, ...attrs }) => {
  const typeList = () => {
    if (type === "unnumbered") return "ul";
    return "ol";
  };

  const renderMarker = () => {
    switch (marker) {
      case "circleEmpty":
        return <Icon mr="12px" size={16} src={CircleEmptyIcon} />;
      case "square":
        return <Icon mr="12px" size={16} src={SquareIcon} />;
      case "squareEmpty":
        return <Icon mr="12px" size={16} src={SquareEmptyIcon} />;
      case "star":
        return <Icon mr="12px" size={16} src={StarIcon} />;
      case "checkmark":
        return <Icon mr="12px" size={16} src={CheckIcon} />;
      default:
        return <Icon mr="12px" size={16} src={CircleIcon} />;
    }
  };

  return (
    <ListStyle as={typeList()} {...attrs}>
      {listItems.map((item, i) => (
        <ListItem key={i}>
          {type === "unnumbered" ? renderMarker() : <Counter>{i + 1}</Counter>}
          {item}
        </ListItem>
      ))}
    </ListStyle>
  );
};

List.propTypes = {
  listItems: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
  marker: PropTypes.string,
};

List.defaultProps = {
  type: "numbered",
  marker: "circle",
  listItems: [],
};

export default List;
