import styled from "styled-components";
import css from "@styled-system/css";
import theme from "~tokens";

export const AvatarStyle = styled("div")(
  css({
    display: "flex",
    borderRadius: "100%",
    background: theme.colors.accentPrimaryLight,
    width: "24px",
    height: "24px",
    minWidth: "24px",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    position: "relative",
    "& > span": {
      font: theme.fonts.textRegularSmall,
      margin: "0 auto",
      color: theme.colors.accentPrimaryDark,
      textTransform: "uppercase",
      "-webkit-touch-callout": "none",
      "-webkit-user-select": "none",
      "-khtml-user-select": "none",
      "-moz-user-select": "none",
      "-ms-user-select": "none",
      "user-select": "none",
      "&.green": {
        color: theme.colors.green400,
      },
      "&.orange": {
        color: theme.colors.accentSecondaryDark,
      },
      "&.yellow": {
        color: theme.colors.yellow400,
      },
    },
    "&.green": {
      background: theme.colors.green100,
    },
    "&.orange": {
      background: theme.colors.accentSecondaryLight,
    },
    "&.yellow": {
      background: theme.colors.yellow100,
    },
    "& > img": {
      width: "100%",
      backgroundSize: "100%",
      borderRadius: "100%",
    },
  }),
);

export const StatusStyle = styled("div")(
  css({
    background: "transparent",
    width: "7px",
    height: "7px",
    borderRadius: "50%",
    right: "0px",
    position: "absolute",
    boxSizing: "border-box",
    borderWidth: "2px",
    border: `1px solid ${theme.colors.grayWhite}`,
    bottom: "0px",
    "&.online": {
      background: theme.colors.green300,
    },
    "&.busy": {
      background: theme.colors.accentSecondaryDark,
    },
    "&.offline": {
      background: theme.colors.gray200,
    },
  }),
);
