import styled from "styled-components";
import css from "@styled-system/css";
import { compose, space, flexbox } from "styled-system";
import theme from "~tokens";

export const Container = styled("div")(
  css({
    display: "flex",
  }),
  compose(space, flexbox),
);

export const PageNavigation = styled("button")(
  css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    bg: theme.colors.grayWhite,
    border: "1px solid",
    borderColor: theme.colors.gray200,
    marginLeft: "1px",
    marginRight: "1px",
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    cursor: "pointer",
    "&:hover": {
      bg: theme.colors.gray100,
    },
    "&:focus": {
      boxShadow: `0 0 0 2px ${theme.colors.accentPrimaryLight}`,
      outline: "none",
      zIndex: 1,
    },
  }),
);

export const PageNumber = styled(PageNavigation)(
  css({
    border: "none",
    marginLeft: "1px",
    marginRight: "1px",
  }),
  ({ unnumbered }) => unnumbered
    && css({
      width: "8px",
      height: "8px",
      bg: theme.colors.gray400,
      opacity: 0.2,
      "&:not(:last-of-type)": {
        mr: "8px",
      },
      "&:hover": {
        bg: theme.colors.gray400,
        opacity: 0.6,
      },
    }),
  ({ unnumbered, selected }) => unnumbered
    && selected
    && css({
      boxShadow: "none",
      opacity: 1,
      "&:hover": {
        opacity: 0.6,
      },
      "&:focus": {
        boxShadow: "none",
      },
    }),
  ({ unnumbered, selected }) => !unnumbered
    && selected
    && css({
      fontWeight: "bold",
      bg: theme.colors.accentPrimaryLight,
      color: theme.colors.accentPrimaryDark,
      borderColor: theme.colors.accentPrimaryLight,
      "&:hover": {
        bg: theme.colors.accentPrimaryLight,
        color: theme.colors.accentPrimaryDark,
      },
    }),
);
