import styled from "styled-components";
import css from "@styled-system/css";
import { compose, space, flexbox, layout } from "styled-system";
import theme from "~tokens";

const { xs } = theme.media;

export const WrapperStyle = styled("main")(
  css({
    display: "flex",
    alignItems: "flex-start",
    position: "relative",
    top: "0",
    left: "0",
    margin: "0",
    padding: "0",
    background: theme.colors.gray100,
    [xs]: {
      display: "block",
    },
  }),
  compose(space, flexbox, layout),
);
