import styled from "styled-components";
import css from "@styled-system/css";
import theme from "~tokens";

export const GroupContent = styled("div")(
  css({
    display: "flex",

    button: {
      borderRadius: "0",
    },
    "&.radio-type": {
      "> div": {
        width: "unset",
        borderRadius: "0",
        "&:hover": {
          boxShadow: theme.effects.buttonHover,
        },
        "&:active, &:focus:active": {
          boxShadow: theme.effects.buttonClick,
          "::before": {
            content: '""',
            position: "absolute",
            borderRadius: theme.spacing.xss,
            bg: theme.colors.grayBlack,
            width: "calc(100% + 0px)",
            height: "calc(100% + 0px)",
            opacity: 0.28,
            left: "0",
            top: "0",
            zIndex: 1,
          },
        },
        "> div": {
          position: "absolute",
          clip: "rect(0,0,0,0)",
          pointerEvents: "none",
        },
        label: {
          background: theme.colors.grayWhite,
          color: theme.colors.gray300,
          border: "solid 2px transparent",
          display: "flex",
          outline: "none",
          cursor: "pointer",
          minHeight: "32px",
          minWidth: "32px",
          borderRadius: "0",
          textAlign: "center",
          alignItems: "center",
          whiteSpace: "nowrap",
          position: "relative",
          justifyContent: "center",
          textTransform: "uppercase",
          transition: "all .15s ease-out",
          font: theme.fonts.textBoldLarge,
          boxShadow: theme.effects.cardShadow,
          padding: "4px 12px",
        },
        ".checked + label": {
          bg: theme.colors.accentPrimaryDark,
          color: theme.colors.grayWhite,
        },
        ".disabled + label": {
          bg: "#f2f2f2",
          color: "#d1d1d1",
        },
      },
      "div:first-child label": {
        borderTopLeftRadius: theme.spacing.xss,
        borderBottomLeftRadius: theme.spacing.xss,
      },
      "div:last-child label": {
        borderTopRightRadius: theme.spacing.xss,
        borderBottomRightRadius: theme.spacing.xss,
      },
    },
    "&.radio-type.secondary": {
      ".checked + label": {
        bg: theme.colors.accentPrimaryLight,
        color: theme.colors.accentPrimaryDark,
      },
    },
    "button:first-child": {
      borderTopLeftRadius: theme.spacing.xss,
      borderBottomLeftRadius: theme.spacing.xss,
    },
    "button:last-child": {
      borderTopRightRadius: theme.spacing.xss,
      borderBottomRightRadius: theme.spacing.xss,
    },
  }),
);
