import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Close, Header, Footer, Title, MoreFilter, Content } from "./SidebarFilterStyle";
import { ReactComponent as CloseIcon } from "~svgs/close.svg";
import { ReactComponent as PlusCircleIcon } from "~svgs/plusCircle.svg";
import Button from "~core/button";

const SidebarFilter = ({
  children,
  open,
  onClose,
  onApply,
  onClear,
  title,
  elementsToShow,
  ...attrs
}) => {
  const [isShowing, setIsShowing] = useState(open);
  const [dataSize, setDataSize] = useState(elementsToShow);

  useEffect(() => setIsShowing(open), [open]);

  const toggle = () => {
    onClose(!isShowing);
    setIsShowing(!isShowing);
  };

  return (
    <Container variant={[isShowing && "open"]} {...attrs}>
      <Header>
        <Title>{title}</Title>
        <Close onClick={toggle}>
          <CloseIcon />
        </Close>
      </Header>
      <Content>
        {children && children.slice(0, dataSize).map((element) => element)}

        {children.length >= elementsToShow && (
          <MoreFilter
            onClick={() => setDataSize(children.length)}
            style={{ display: children.length === dataSize && "none" }}
          >
            <PlusCircleIcon />
            <p>Mais filtros</p>
          </MoreFilter>
        )}
      </Content>
      <Footer>
        <Button name="Aplicar" variant="secondary" onClick={onApply} />
        <Button name="Limpar tudo" variant="tertiary" onClick={onClear} />
      </Footer>
    </Container>
  );
};

SidebarFilter.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onApply: PropTypes.func,
  onClear: PropTypes.func,
  title: PropTypes.string,
  elementsToShow: PropTypes.number,
};

SidebarFilter.defaultProps = {
  children: "",
  open: false,
  onClose: () => {},
  onApply: () => {},
  onClear: () => {},
  title: "",
  elementsToShow: 5,
};

export default SidebarFilter;
