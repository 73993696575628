import styled from "styled-components";
import css from "@styled-system/css";
import { compose, variant, space, layout } from "styled-system";
import theme from "~tokens";

export const ThemedButton = styled("button")(
  css({
    bg: theme.colors.accentPrimaryDark,
    border: "solid 2px transparent",
    display: "flex",
    outline: "none",
    cursor: "pointer",
    minHeight: "32px",
    minWidth: "32px",
    width: "auto",
    color: theme.colors.grayWhite,
    borderRadius: "4px",
    textAlign: "center",
    alignItems: "center",
    whiteSpace: "nowrap",
    position: "relative",
    justifyContent: "center",
    textTransform: "uppercase",
    transition: "all .15s ease-out",
    font: theme.fonts.textBoldLarge,
    boxShadow: theme.effects.cardShadow,
    "> div": {
      padding: "4px 12px",
      outline: "none",
      "&.icon-button": {
        padding: "4px",
      },
      svg: {
        pointerEvents: "none",
        path: {
          fill: theme.colors.grayWhite,
        },
      },
    },
    "&:hover": {
      boxShadow: theme.effects.buttonHover,
    },
    "&:focus": {
      border: theme.effects.buttonFocus,
      boxShadow: theme.effects.cardShadow
    },
    "&:hover:focus": {
      border: theme.effects.buttonFocus,
      boxShadow: theme.effects.buttonHover,
    },
    "&:active, &:focus:active": {
      boxShadow: theme.effects.buttonClick,
      "::before": {
        content: '""',
        position: "absolute",
        borderRadius: "4px",
        bg: theme.colors.grayBlack,
        width: "calc(100% + 4px)",
        height: "calc(100% + 4px)",
        opacity: 0.28,
        left: "-2px",
        top: "-2px",
        zIndex: 2,
      },
    },

    "&:disabled": {
      bg: theme.colors.gray100,
      color: theme.colors.gray200,
      boxShadow: "none",
      cursor: "unset",
      "> div svg path": {
        fill: theme.colors.gray200,
      },
      "&:hover": {
        boxShadow: "none",
      },
      "::before": {
        bg: "transparent",
      }
    },
    "&.cancel": {
      background: "transparent",
      color: theme.colors.accentPrimaryDark,
      boxShadow: "none",
    },
  }),
  ({ onlyIcon }) => onlyIcon && css({ padding: 0, "> div svg": { mr: 0 } }),
  variant({
    variants: {
      secondary: {
        bg: theme.colors.accentPrimaryLight,
        color: theme.colors.accentPrimaryDark,
        "> div svg path": {
          fill: theme.colors.accentPrimaryDark,
        },
        "&:focus": {
          borderColor: "#FFFFFF",
          boxShadow: theme.effects.cardShadow,
        },
        "&:hover:focus": {
          borderColor: "#FFFFFF",
          boxShadow: theme.effects.buttonHover,
        },
      },
      tertiary: {
        bg: "transparent",
        color: theme.colors.accentPrimaryDark,
        boxShadow: "none",
        "> div svg path": {
          fill: theme.colors.accentPrimaryDark,
        },
        "&:hover": {
          bg: theme.colors.grayWhite,
        },
        "&:disabled": {
          bg: "transparent",
          color: theme.colors.gray300,
          cursor: "unset",
          "> div svg path": {
            fill: theme.colors.gray300,
          },
          "&:hover, &:focus, &:active, &:focus:active": {
            bg: "transparent",
            border: "none",
            boxShadow: "none",
            "::before": {
              bg: "transparent",
            }
          },
        },
      },
    },
  }),
  compose(space, layout),
);
