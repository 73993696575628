const generateUUID = () => {
  let date = new Date().getTime();
  if (typeof performance !== "undefined" && typeof performance.now === "function") {
    date += performance.now();
  }
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const radom = (date + Math.random() * 16) % 16 | 0;
    date = Math.floor(date / 16);
    // eslint-disable-next-line no-bitwise
    return (c === "x" ? radom : radom & (0x3 | 0x8)).toString(16);
  });
};
export default generateUUID;
