import styled from "styled-components";
import css from "@styled-system/css";
import { compose, space, layout } from "styled-system";
import theme from "~tokens";

export const LabelStyle = styled("label")(
  css({
    font: theme.fonts.textRegularSmall,
    color: theme.colors.gray400,
    display: "flex",
    span: {
      margin: "0 4px",
      position: "relative",
      ":first-of-type": {
        margin: "0px",
      },
      "&.informative": {
        color: theme.colors.gray200,
      },
    },
  }),
  compose(layout, space),
);
