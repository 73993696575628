import React, { useState } from "react";
import PropTypes from "prop-types";
import { SelectGroupDropdown } from "../select/SelectStyle";
import EmptyState from "~core/empty-state";
import Icon from "~core/icon";
import {
  Container,
  InputStyle,
  HeaderSearchStyle,
  HeaderBreadCrumb,
  HeaderContent,
  SearchLabel,
  SearchIconStyle,
} from "./searchBarStyle";
import { SearchButtonIcon } from "~svgs";

const SearchBar = ({ autocomplete, options, value, omnisearch, onClick, onChange, ...attr }) => {
  const [valueChangeInput, setValueChangeInput] = useState(value);
  const [open, setOpen] = useState(false);
  const [optionsInput] = useState(options);
  const regex = new RegExp(`(${valueChangeInput})`, "gi");
  const dataForFilter = optionsInput.search ? optionsInput.search.data : optionsInput;
  const filter = dataForFilter.filter((option) => option.label.match(regex));
  let tabIndexValue = -1;

  const renderOptionsAutocomplete = () => (
    <SelectGroupDropdown active={open} className={omnisearch && "omnisearch"}>
      <ul key={Math.random()}>
        {optionsInput.header && (
          <HeaderSearchStyle>
            <HeaderBreadCrumb>{optionsInput.header.breadcrumb}</HeaderBreadCrumb>
            <HeaderContent>
              {optionsInput.header.icon}
              {filter.length > 0 && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: filter[0].label.replace(regex, `<strong>${"$1"}</strong>`),
                  }}
                />
              )}
            </HeaderContent>
          </HeaderSearchStyle>
        )}
        {optionsInput.search && <SearchLabel>{optionsInput.search.label}</SearchLabel>}
        {filter.length > 0 ? (
          filter.map((option, index) => (
            <li
              role="presentation"
              key={`${index}_${Math.random()}`}
              id={`search-li-${index}`}
              tabIndex={index}
              onKeyUp={(e) => {
                if (e.keyCode === 40) {
                  document.getElementById(`search-li-${index + 1}`).focus();
                } else if (e.keyCode === 38) {
                  document.getElementById(`search-li-${index - 1}`).focus();
                } else if (e.keyCode === 13) {
                  document.getElementById(`search-button-${index}`).click();
                }
              }}
            >
              <button
                type="button"
                onClick={(e) => {
                  setValueChangeInput(option.label);
                  setOpen(!open);
                  onClick(e, option.label);
                }}
                id={`search-button-${index}`}
                title="Selecionar opção"
                aria-label="Selecionar opção"
              >
                {optionsInput.search && optionsInput.search.icon && (
                  <SearchIconStyle>{optionsInput.search.icon}</SearchIconStyle>
                )}
                <span
                  dangerouslySetInnerHTML={{
                    __html: option.label.replace(regex, `<strong>${"$1"}</strong>`),
                  }}
                />
              </button>
            </li>
          ))
        ) : (
          <li>
            <EmptyState condensed title="Nenhum resultado foi encontrado." />
          </li>
        )}
      </ul>
    </SelectGroupDropdown>
  );

  return (
    <Container>
      <Icon src={SearchButtonIcon} className="search-icon" />
      <InputStyle
        {...attr}
        role="searchbox"
        value={valueChangeInput}
        type="text"
        onChange={(e) => {
          setValueChangeInput(e.target.value);
          setOpen(true);
          onChange(e);
        }}
        onKeyUp={(e) => {
          if (e.keyCode === 40) {
            tabIndexValue += 1;
            document.getElementById(`search-li-${tabIndexValue}`).focus();
          } else if (e.keyCode === 38) {
            tabIndexValue -= 1;
            document.getElementById(`search-li-${tabIndexValue}`).focus();
          }
        }}
      />
      {autocomplete && valueChangeInput !== "" && renderOptionsAutocomplete()}
    </Container>
  );
};

SearchBar.propTypes = {
  autocomplete: PropTypes.bool,
  options: PropTypes.array,
  value: PropTypes.string,
  omnisearch: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

SearchBar.defaultProps = {
  autocomplete: false,
  options: [],
  value: "",
  omnisearch: false,
  onChange: () => {},
  onClick: () => {},
};

export default SearchBar;
