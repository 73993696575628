import React from "react";
import PropTypes from "prop-types";
import { OpacityDivStyle } from "./OpacityDivStyle";

const OpacityDiv = ({ children, show, ...attrs }) => (
  <OpacityDivStyle className={show && "show"} data-testid="opacity-div" {...attrs}>
    {children}
  </OpacityDivStyle>
);

OpacityDiv.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node,
};

OpacityDiv.defaultProps = {
  show: false,
  children: "",
};

export default OpacityDiv;
