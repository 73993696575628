import styled from "styled-components";
import css from "@styled-system/css";
import { compose, space, layout } from "styled-system";
import theme from "~tokens";

export const OmniSearchHeaderStyle = styled("div")(
  css({
    width: "100%",
    background: theme.colors.accentPrimaryLight,
  }),
  compose(layout, space),
);
