import styled from "styled-components";
import css from "@styled-system/css";
import {
  compose, space, flexbox, layout
} from "styled-system";
import theme from "~tokens";

export const OpacityDivStyle = styled("div")(
  css({
    "&:last-of-type": {
      position: "fixed ",
      background: theme.colors.grayBlack,
      top: "0",
      width: "100%",
      height: "100%",
      opacity: "0.5",
      left: "0",
      display: "none",
      transition: "0.8s",
      "&.show": {
        display: "block"
      }
    },
    "&:fist-of-type": {
      position: "fixed ",
      background: theme.colors.grayBlack,
      top: "0",
      width: "100%",
      height: "100%",
      opacity: "0.5",
      left: "0",
      display: "none",
      transition: "0.8s",
      "&.show": {
        display: "block"
      }
    }
  }),
  compose(space, flexbox, layout)
);
