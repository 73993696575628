import styled from "styled-components";
import css from "@styled-system/css";
import {
  compose, space, flexbox, layout
} from "styled-system";
import theme from "~tokens";

export const Input2faStyle = styled("input")(
  css({
    width: "44px",
    minWidth: "44px",
    height: "60px",
    textAlign: "center",
    background: theme.colors.gray100,
    border: `1px solid ${theme.colors.gray200}`,
    color: theme.colors.gray500,
    fontSize: "32px",
    margin: "4px",
    borderRadius: "4px",
    ":first-of-type": {
      marginLeft: "0px",
    },
    ":last-of-type": {
      marginRight: "0px",
    },
    ":focus, :focus-within, :active": {
      border: `1px solid ${theme.colors.accentPrimaryDark}`,
      boxShadow: `0px 0px 0px 2px ${theme.colors.accentPrimaryLight}`,
    },
    "&.password": {
      fontSize: "44px",
      paddingBottom: "5px",
    },
  }),
  compose(space, flexbox, layout),
);
