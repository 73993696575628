import React from "react";
import PropTypes from "prop-types";
import { CommentStyle, AvatarContainer, CommentContainer } from "./CommentStyle";

const Comment = ({ children, avatar, ...attrs }) => (
  <CommentStyle {...attrs} data-testid="comment">
    {avatar && <AvatarContainer>{avatar}</AvatarContainer>}
    <CommentContainer>{children}</CommentContainer>
  </CommentStyle>
);

Comment.propTypes = {
  avatar: PropTypes.node,
  children: PropTypes.node,
};

Comment.defaultProps = {
  avatar: "",
  children: "",
};

export default Comment;
