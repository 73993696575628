import styled from "styled-components";
import css from "@styled-system/css";
import {
  compose, space, flexbox, layout
} from "styled-system";
import theme from "~tokens";

export const ChatFieldContainer = styled("div")(
  css({
    display: "flex",
    width: "100%",
    position: "relative",
    background: "transparent",
  }),
  compose(space, flexbox, layout),
);

export const FiledStyle = styled("input")(
  css({
    display: "flex",
    width: "100%",
    background: theme.colors.grayWhite,
    boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.1)",
    border: `2px solid ${theme.colors.grayWhite}`,
    padding: "8px",
    paddingRight: "47px",
    paddingLeft: "40px",
    color: theme.colors.gray500,
    fontSize: "14px",
    "&:focus": {
      border: `2px solid ${theme.colors.accentPrimaryLight}`,
    },
    "$:placeholder": {
      color: theme.colors.gray200,
    },
  }),
);

export const SendButton = styled("button")(
  css({
    position: "absolute",
    background: "transparent",
    border: "0",
    cursor: "pointer",
    height: "100%",
    right: "0",
    paddingRight: "13px",
    "svg path": {
      fill: theme.colors.gray200,
    }
  }),
);

export const AttachButton = styled("button")(
  css({
    position: "absolute",
    width: "28px",
    background: "transparent",
    border: "0",
    cursor: "pointer",
    height: "100%",
    left: "8px",
    "svg path": {
      fill: theme.colors.gray200,
    }
  }),
);
