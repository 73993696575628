import React, { useState, useRef, useEffect, Fragment, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { FilterIcon } from "~svgs";
import Checkbox from "~form/checkbox";
import Radio from "~form/radio";
import {
  FilterStyle,
  ButtonFilter,
  ItemCounter,
  OptionsList,
  GroupItem,
  GroupTitle,
  Group,
  GroupWrapper,
  ComponentWrapper,
} from "./FilterStyle";

const FilterInputType = {
  checkbox: "checkbox",
  radio: "radio",
};

const Filter = ({
  listItems,
  onChange,
  checkedOptions,
  direction,
  inputType,
  isChecked,
  component: CallbackComponent,
}) => {
  const optionsRef = useRef(null);
  const concatArray = [...new Set([].concat(...listItems.map((item) => item.data)))];
  const selected = concatArray.filter((item) => item.checked === true);
  const defaultSelected = selected.map((c) => c.label);

  const [openOptions, setOpenOptions] = useState(false);
  const [numberItemsSelected, setNumberItemsSelected] = useState(isChecked ? selected.length : 0);
  const [selectedItems, setSelectedItems] = useState(isChecked ? defaultSelected : []);

  const checkboxVariant = useMemo(() => inputType === FilterInputType.checkbox, [inputType]);

  const handleClickOutside = useCallback((e) => {
    if (!optionsRef.current?.contains(e.target)) {
      setOpenOptions(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  useEffect(() => {
    checkedOptions(selectedItems);
  }, [checkedOptions, selectedItems]);

  const addItemCheckbox = (value) => {
    setSelectedItems([...selectedItems, value]);
    setNumberItemsSelected(numberItemsSelected + 1);
  };

  const removeItemCheckbox = (value) => {
    setSelectedItems((olders) => olders.filter((item) => item !== value));
    setNumberItemsSelected(numberItemsSelected - 1);
  };

  const handleItemCheckbox = (value, isSelected) => {
    if (isSelected) removeItemCheckbox(value);
    else addItemCheckbox(value);
    onChange(value);
  };

  const addItemRadio = (value) => {
    setSelectedItems((olders) => {
      const newItems = [...olders.filter((item) => item.label !== value.label), value];
      setNumberItemsSelected(newItems.length);
      return newItems;
    });
  };

  const removeItemRadio = (value) => {
    setSelectedItems((olders) => {
      const newItems = olders.filter((item) => item.label !== value.label);
      setNumberItemsSelected(newItems.length);
      return newItems;
    });
  };

  const handleItemRadio = (value, isSelected) => {
    if (isSelected) removeItemRadio(value);
    else addItemRadio(value);
    onChange(value.item);
  };

  const getSelected = (item) => {
    if (checkboxVariant) {
      return selectedItems.includes(item);
    }
    return !!selectedItems.find((elem) => elem.item === item);
  };

  const getOnClick = (label, item, isSelected) => {
    if (checkboxVariant) {
      return handleItemCheckbox(item, isSelected);
    }
    return handleItemRadio({ label, item }, isSelected);
  };

  const renderOptions = ({ label, data }) => {
    renderOptions.propTypes = {
      label: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.object).isRequired,
    };
    if (data.length > 0) {
      return (
        <Group key={label}>
          {label && <GroupTitle>{label}</GroupTitle>}
          <GroupWrapper flexDirection={direction}>
            {data.map((item) => {
              const isSelected = getSelected(isChecked ? item.label : item);
              return (
                <GroupItem
                  key={isChecked ? item.label : item}
                  selected={isSelected}
                  onClick={() => getOnClick(label, isChecked ? item.label : item, isSelected)}
                >
                  {checkboxVariant ? (
                    <Checkbox
                      label={isChecked ? item.label : item}
                      key={`${label}-${isChecked ? item.label : item}`}
                      id={`${label}-${isChecked ? item.label : item}`}
                      checked={isChecked ? item.checked || isSelected : isSelected}
                      group={label}
                      onChange={() => getOnClick(label, isChecked ? item.label : item, isSelected)}
                    />
                  ) : (
                    <Radio
                      label={isChecked ? item.label : item}
                      checked={isChecked ? item.checked || isSelected : isSelected}
                      onChange={() => getOnClick(label, isChecked ? item.label : item, isSelected)}
                    />
                  )}
                </GroupItem>
              );
            })}
          </GroupWrapper>
        </Group>
      );
    }
    return null;
  };

  return (
    <Fragment>
      <FilterStyle id="filter" data-testid="filter">
        <ButtonFilter
          variant={[(openOptions || numberItemsSelected > 0) && "focus"]}
          onClick={() => {
            setOpenOptions(!openOptions);
            checkedOptions(selectedItems);
          }}
        >
          <FilterIcon />
          {numberItemsSelected > 0 && <ItemCounter>{numberItemsSelected}</ItemCounter>}
        </ButtonFilter>
      </FilterStyle>
      {openOptions && (
        <OptionsList id="options-filter" data-testid="options-filter" ref={optionsRef}>
          {listItems.map(renderOptions)}
          <ComponentWrapper>{CallbackComponent}</ComponentWrapper>
        </OptionsList>
      )}
    </Fragment>
  );
};

Filter.propTypes = {
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      data: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, checked: PropTypes.bool })),
        PropTypes.arrayOf(PropTypes.string),
      ]),
    }),
  ).isRequired,
  onChange: PropTypes.func,
  checkedOptions: PropTypes.func,
  direction: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  inputType: PropTypes.string,
  isChecked: PropTypes.bool,
};

Filter.defaultProps = {
  onChange: () => {},
  checkedOptions: () => {},
  direction: "column",
  component: "",
  inputType: "checkbox",
  isChecked: false,
};

export default Filter;
