import React from "react";
import PropTypes from "prop-types";
import { BubbleContainer, BubbleContent } from "./ChatBubbleStyle";

const ChatBubble = ({ children, sent, ...attrs }) => (
  <BubbleContainer className={sent && "sent"} {...attrs} data-testid="bubble-chat">
    <BubbleContent className={sent && "sent"}>{children}</BubbleContent>
  </BubbleContainer>
);

ChatBubble.propTypes = {
  sent: PropTypes.bool,
  children: PropTypes.node,
};

ChatBubble.defaultProps = {
  sent: false,
  children: "",
};

export default ChatBubble;
