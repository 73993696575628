import React from "react";
import PropTypes from "prop-types";
import { MediaWrapper, MediaSource, Article } from "./MediaCardStyle";
import Heading from "~typography/heading";
import Text from "~typography/text";

const MediaCard = ({ title, children, titleSize, description, ...attrs }) => (
  <MediaWrapper {...attrs} data-testid="media-card">
    <MediaSource>{children}</MediaSource>
    {title && (
      <Article>
        <header>
          <Heading size={titleSize}>{title}</Heading>
        </header>
        <Text>{description}</Text>
      </Article>
    )}
  </MediaWrapper>
);

MediaCard.propTypes = {
  title: PropTypes.string,
  mediaTitle: PropTypes.string,
  children: PropTypes.node,
  titleSize: PropTypes.number,
  description: PropTypes.string,
};

MediaCard.defaultProps = {
  title: "",
  mediaTitle: "",
  children: "",
  titleSize: 14,
  description: "",
};

export default MediaCard;
