import React, { useState, useEffect, useContext, Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { MenuItemStyle } from "./MenuItemStyle";
import Icon from "~core/icon";
import Tooltip from "~core/tooltip";
import { ChevronUpIcon } from "~svgs";
import MenuContext from "./MenuContext";
import Text from "~typography/text";
import theme from "~tokens";

const MenuItem = ({
  children,
  active,
  icon,
  tooltipTitle,
  subItem,
  title,
  secondaryMenu,
  ...attrs
}) => {
  const [isSubOpen, setSubOpen] = useState(false);
  const { isCollapse, toggleCollapse } = useContext(MenuContext);

  useEffect(() => {
    if (isCollapse) {
      setSubOpen(false);
    }
  }, [isCollapse]);

  const toggleSubMenu = () => {
    if (isCollapse) {
      toggleCollapse();
      setSubOpen(true);
    } else {
      setSubOpen(!isSubOpen);
    }
  };

  return (
    <MenuItemStyle
      className={clsx(active && "active", isSubOpen && "open-sub-item", subItem && "sub-item")}
      {...attrs}
    >
      <Tooltip title={tooltipTitle} className="tooltip" id={tooltipTitle}>
        {subItem ? (
          <Fragment>
            <button type="button" onClick={() => toggleSubMenu()} className="title-item">
              {icon && (
                <Icon
                  fill={active ? theme.colors.accentPrimaryDark : theme.colors.gray400}
                  size={24}
                  src={icon}
                />
              )}
              <Text color={active ? theme.colors.accentPrimaryDark : theme.colors.gray400}>
                {title}
              </Text>
              {!isCollapse && <Icon size={16} src={ChevronUpIcon} className="sub-item-arrow" />}{" "}
            </button>
            <div className="sub-items">{children}</div>
          </Fragment>
        ) : (
          <Fragment>
            {icon && (
              <Icon
                fill={active ? theme.colors.accentPrimaryDark : theme.colors.gray400}
                size={24}
                src={icon}
              />
            )}
            <Text color={active ? theme.colors.accentPrimaryDark : theme.colors.gray400}>
              {children}
            </Text>
          </Fragment>
        )}
      </Tooltip>
    </MenuItemStyle>
  );
};

MenuItem.propTypes = {
  icon: PropTypes.any,
  tooltipTitle: PropTypes.string,
  children: PropTypes.node,
  active: PropTypes.bool,
  subItem: PropTypes.bool,
  title: PropTypes.string,
  secondaryMenu: PropTypes.bool,
};

MenuItem.defaultProps = {
  icon: "",
  tooltipTitle: "",
  children: "",
  active: false,
  subItem: false,
  title: "",
  secondaryMenu: false,
};

export default MenuItem;
