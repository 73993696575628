import React, { useState } from "react";
import PropTypes from "prop-types";
import { SelectGroupDropdown } from "~form/select/SelectStyle";
import Icon from "~core/icon";
import EmptyState from "~core/empty-state";
import { Container, InputStyle } from "./SearchButtonStyle";
import { SearchButtonIcon } from "~svgs";

const SearchButton = ({ autocomplete, options, value, ...attr }) => {
  const [valueChangeInput, setValueChangeInput] = useState(value);
  const [open, setOpen] = useState(false);
  const [openOptions, setOpenOptions] = useState(false);
  const [optionsInput] = useState(options);
  const regex = new RegExp(`(${valueChangeInput})`, "gi");
  const filter = optionsInput.filter((option) => option.label.match(regex));

  const renderOptionsAutocomplete = () => (
    <SelectGroupDropdown active={openOptions}>
      <ul key={Math.random()}>
        {filter.length > 0 ? (
          filter.map((option, index) => (
            <li key={`${index}_${Math.random()}`}>
              <button
                type="button"
                onClick={() => {
                  setOpenOptions(!openOptions);
                  setValueChangeInput(option.label);
                }}
                title="Selecionar opção"
                aria-label="Selecionar opção"
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: option.label.replace(regex, `<strong>${"$1"}</strong>`),
                  }}
                />
              </button>
            </li>
          ))
        ) : (
          <li>
            <EmptyState condensed title="Nenhum resultado foi encontrado." />
          </li>
        )}
      </ul>
    </SelectGroupDropdown>
  );

  const timeCloseAutocomplete = () => {
    setTimeout(() => {
      setOpenOptions(false);
    }, 200);
  };

  const timeCloseInput = () => {
    setTimeout(() => {
      setOpen(false);
      setOpenOptions(false);
    }, 200);
  };

  return (
    <Container>
      <Icon
        src={SearchButtonIcon}
        className="search-icon"
        onClick={() => {
          setOpen(true);
          if (valueChangeInput !== "") setOpenOptions(true);
        }}
      />
      <InputStyle
        {...attr}
        role="searchbox"
        value={valueChangeInput}
        type="text"
        open={open}
        isEmpty={valueChangeInput === ""}
        onChange={(e) => {
          setValueChangeInput(e.target.value);
          setOpen(true);
          setOpenOptions(true);
        }}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            setOpen(false);
          }
          if (e.key === "Enter") {
            setOpen(true);
          }
        }}
        onClick={() => {
          setOpen(true);
          if (valueChangeInput !== "") setOpenOptions(true);
        }}
        onBlur={() => {
          if (valueChangeInput === "") timeCloseInput();
          else timeCloseAutocomplete();
        }}
      />
      {autocomplete && openOptions && valueChangeInput !== "" && renderOptionsAutocomplete()}
    </Container>
  );
};

SearchButton.propTypes = {
  autocomplete: PropTypes.bool,
  options: PropTypes.array,
  value: PropTypes.string,
};

SearchButton.defaultProps = {
  autocomplete: false,
  options: [],
  value: "",
};

export default SearchButton;
