import React from "react";
import PropTypes from "prop-types";
import { RadioContainer, RadioStyle } from "./RadioStyle";
import Label from "~form/label";

const Radio = ({
  onChange, label, checked, disabled, ...attrs
}) => (
  <RadioContainer>
    <input
      type="radio"
      disabled={disabled}
      checked={checked}
      onChange={(e) => onChange(e)}
      {...attrs}
    />
    <RadioStyle className={(checked && "checked") || (disabled && "disabled")} />
    {label && <Label>{label}</Label>}
  </RadioContainer>
);

Radio.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

Radio.defaultProps = {
  onChange: () => {},
  label: "",
  checked: false,
  disabled: false,
};

export default Radio;
