/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

import { TooltipCard, TooltipContent } from "./TooltipStyle";

const Tooltip = ({ title, children, place, offset, multiline, id, clickable, ...attrs }) => (
  <TooltipCard className="tooltip-wrapper" data-testid="tooltip">
    <TooltipContent data-tip={title} data-for={id}>
      {children}
    </TooltipContent>
    <ReactTooltip
      place={place}
      multiline={multiline}
      offset={offset}
      id={id}
      clickable={clickable}
      {...attrs}
    />
  </TooltipCard>
);

Tooltip.propTypes = {
  /**
   * você pode ter muitos <ReactTooltip /> em uma página, mas eles devem ter ids diferentes
   */
  id: PropTypes.any,
  title: PropTypes.string,
  /**
   * Posição do tooltip sobre o hover
   */
  place: PropTypes.oneOf(["top", "bottom", "right", "left"]),
  /**
   * Offset do tool tip
   */
  offset: PropTypes.oneOf({ top: 10, left: 10 }),
  /**
   * Aceita <br>, <br /> para multiplas linhas
   */
  multiline: PropTypes.bool,
  clickable: PropTypes.bool,
};

Tooltip.defaultProps = {
  id: "tool",
  title: "",
  place: "top",
  offset: {},
  multiline: false,
  clickable: false,
};

export default Tooltip;
