/* eslint-disable react/prop-types */
import React from "react";
import { DotCicle } from "./DotStyle";

const CustomDot = ({ carouselItems, onClick, ...rest }) => {
  const {
    index,
    active,
  } = rest;
  return (
    <DotCicle type="button" active={active} onClick={() => onClick()}>
      {React.Children.toArray(carouselItems)[index]}
    </DotCicle>
  );
};

export default CustomDot;
