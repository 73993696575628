import React from "react";
import PropTypes from "prop-types";
import { LinkStyle, LinkName } from "./LinkStyle";

const Link = ({ nameLink, href, tag, target, fileName, textDecoration, ...attrs }) => (tag !== "button" ? (
  <LinkStyle
    as={tag}
    title={nameLink}
    href={href || ""}
    rel={target === "_blank" ? "noopener noreferrer" : ""}
    target={target}
    variant={[textDecoration]}
    {...attrs}
  >
    <LinkName>{nameLink}</LinkName>
  </LinkStyle>
) : (
  <LinkStyle as={tag} variant={[textDecoration]} {...attrs}>
    <LinkName>{nameLink}</LinkName>
  </LinkStyle>
));

Link.propTypes = {
  nameLink: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  fileName: PropTypes.string,
  textDecoration: PropTypes.string,
  tag: PropTypes.string,
};

Link.defaultProps = {
  nameLink: "Name link",
  href: "#",
  target: "_blank",
  fileName: "",
  textDecoration: "underline",
  tag: "a",
};

export default Link;
