import React from "react";
import PropTypes from "prop-types";
import {
  NotificationContainer,
  NotificationItem,
  NotificationContent,
  NotificationHeader,
  CloseStyle,
  FooterNotification,
} from "./NotificationStyle";
import Icon from "~core/icon";
import { Logo } from "~svgs";
import { ReactComponent as CloseIcon } from "~svgs/close.svg";
import Heading from "~typography/heading";

const NotificationPush = ({
  children,
  title,
  onClose,
  isOpen,
  footerDescription,
  logo,
  ...attrs
}) => (
  <NotificationContainer data-testid="notification-push">
    <NotificationContent className={isOpen && "open"} {...attrs}>
      <NotificationItem className="logo">{!logo && <Icon size={64} src={Logo} />}</NotificationItem>
      <NotificationItem>
        <NotificationHeader>
          <Heading size={16}>{title}</Heading>
          <CloseStyle onClick={onClose}>
            <CloseIcon />
          </CloseStyle>
        </NotificationHeader>
        {children}
        <FooterNotification>{footerDescription}</FooterNotification>
      </NotificationItem>
    </NotificationContent>
  </NotificationContainer>
);

NotificationPush.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  footerDescription: PropTypes.string,
  logo: PropTypes.any,
};

NotificationPush.defaultProps = {
  children: "",
  title: "",
  onClose: () => {},
  isOpen: false,
  logo: "",
  footerDescription: "",
};

export default NotificationPush;
