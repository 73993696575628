import styled from "styled-components";
import css from "@styled-system/css";
import { compose, space, layout } from "styled-system";
import theme from "~tokens";

export const Container = styled("div")(
  css({
    display: "flex",
    alignItems: "center",
    label: {
      font: theme.fonts.textRegularMedium,
      lineHeight: "20px",
      color: "#333333",
      "span:first-of-type": {
        top: "0",
      }
    },
    "label:not(:first-child)": {
      marginLeft: "8px",
    }
  }),
);

export const ContainerInput = styled("label")(
  css({
    position: " relative",
    display: " inline-block",
    width: " 32px",
    height: " 18px",
    input: {
      opacity: " 0",
      width: " 0",
      height: " 0",
    },
    "> .slider": {
      position: "absolute",
      display: "inline-flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      height: "100%",
      padding: "2px",
      backgroundColor: "#FFFFFF",
      border: "1px solid #CCC",
      "-webkit-transition": " .4s",
      transition: " .4s",
      ":before": {
        content: '""',
        height: "12px",
        width: "12px",
        backgroundColor: " white",
        "-webkit-transition": " .4s",
        transition: " .4s",
      },
    },
    "> input:not(:disabled) + .slider": {
      cursor: "pointer",
    },
    "> input:disabled + .slider": {
      cursor: "not-allowed",
    },
    "> input:checked:not(:disabled) + .slider": {
      border: "1px solid #336EAE;",
    },
    "> input:focus + .slider": {
      boxShadow: "0 0 1px #2196F3",
    },
    "> input:checked:not(:disabled) + .slider:before": {
      backgroundColor: "#336EAE",
    },
    "> input:checked + .slider:before": {
      "-webkit-transform": " translateX(calc(100% + 2px))",
      "-ms-transform": " translateX(calc(100% + 2px))",
      transform: " translateX(calc(100% + 2px))",
    },
    ".slider.round": {
      borderRadius: " 12px",
      ":before": {
        borderRadius: " 50%",
        backgroundColor: "#CCCCCC",
      },
    },
  }),
  compose(layout, space),
);
