import styled from "styled-components";
import css from "@styled-system/css";
import theme from "~tokens";

export const Container = styled("div")(
  css({
    position: "relative",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    bg: theme.colors.grayWhite,
    boxShadow: theme.effects.cardShadow,
    borderRadius: "4px",
    padding: "12px",
    font: theme.fonts.textRegularMedium,
  }),
);

export const Content = styled("div")(
  css({
    color: theme.colors.gray400,
  }),
);
