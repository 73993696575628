/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Icon from "~core/icon";
import BreadcrumbItem from "./BreadcrumbItem";
import { BreadcrumbHomeIcon } from "~svgs";
import { Container, ListTag } from "./BreadcrumbStyle";

const Breadcrumb = ({
  bread,
  className,
  listClassName,
  tag,
  listTag,
  "aria-label": label,
  ...attrs
}) => {
  const listClasses = classNames(listClassName);
  const [crumbs, setCrumbs] = useState([]);

  useEffect(() => {
    if (Array.isArray(bread)) {
      setCrumbs(bread);
    } else {
      const path = bread.split("http://").join().split("https://").join()
        .split("/");
      setCrumbs(path.slice(1, path.length));
    }
  }, [bread]);

  const getCrumbURL = (crumb) =>
    Array.isArray(bread) ? crumb.href : bread.slice(0, bread.indexOf(crumb) + crumb.length);

  const getCrumbText = (crumb) => (Array.isArray(bread) ? crumb.text : crumb.replace("-", " "));

  const getCrumbTag = (crumb) => {
    if (Array.isArray(bread)) {
      return crumb.disabled || !crumb.href ? "span" : "a";
    }
    return "a";
  };

  const getOnClick = (crumb) => {
    if (Array.isArray(bread)) {
      return crumb.onClick;
    }
    return null;
  };

  return (
    <Container as={tag} {...attrs} aria-label={label}>
      <ListTag as={listTag} className={listClasses}>
        <a href="/">
          <Icon mr="2px" size={12} src={BreadcrumbHomeIcon} />
        </a>
        {crumbs.map((crumb, index) => (
          <BreadcrumbItem
            key={index}
            href={getCrumbURL(crumb)}
            tag={getCrumbTag(crumb)}
            onClick={getOnClick(crumb)}
          >
            {getCrumbText(crumb)}
          </BreadcrumbItem>
        ))}
      </ListTag>
    </Container>
  );
};

Breadcrumb.propTypes = {
  className: PropTypes.string,
  listClassName: PropTypes.string,
  bread: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        href: PropTypes.string,
        disabled: PropTypes.bool,
        onClick: PropTypes.func,
      }),
    ),
  ]).isRequired,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  listTag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  "aria-label": PropTypes.string,
};

Breadcrumb.defaultProps = {
  className: "",
  listClassName: "",
  tag: "nav",
  listTag: "ol",
  "aria-label": "breadcrumb navigation",
};

export default Breadcrumb;
