import React from "react";
import PropTypes from "prop-types";

import Breadcrumb from "~interface/breadcrumb";
import Heading from "~typography/heading";

import { HeaderContainerStyle, HeaderActionsStyle } from "./HeaderStyle";

const Header = ({ tag, title, bread, children }) => (
  <HeaderContainerStyle as={tag} id="header">
    <Heading tag="h1" variant={[{ bold: true }]}>
      {title}
    </Heading>
    {bread && <Breadcrumb bread={bread} />}
    <HeaderActionsStyle>{children}</HeaderActionsStyle>
  </HeaderContainerStyle>
);

Header.propTypes = {
  bread: PropTypes.string,
  title: PropTypes.string.isRequired,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.node,
};

Header.defaultProps = {
  bread: "",
  tag: "header",
  children: "",
};

export default Header;
